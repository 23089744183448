import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";

function Confirmation({
  openconfirm,
  heading,
  bodyText,
  close,
  onAction,
  cancelBtn,
  actionBtnName,
}: ConfirmationProps) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      fullScreen={fullScreen}
      open={openconfirm}
      maxWidth="lg"
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">{heading}</DialogTitle>
      <DialogContent style={{ wordSpacing: "4.3px" }}>{bodyText}</DialogContent>
      <DialogActions>
        {cancelBtn && (
          <Button autoFocus onClick={close} color="secondary">
            {cancelBtn}
          </Button>
        )}
        <Button onClick={onAction} color="primary" autoFocus>
          {actionBtnName}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

interface ConfirmationProps {
  actionBtnName: string;
  cancelBtn: string;
  heading: string;
  bodyText: React.ReactNode[] | string;
  close: () => void;
  onAction: () => void;
  openconfirm: boolean;
}

export default Confirmation;
