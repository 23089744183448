import Cookies from "js-cookie";
import axios from "axios";

axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
const axiosInstance = axios.create({
  // baseURL: process.env.REACT_APP_BASE_URL || undefined,
  baseURL: import.meta.env.VITE_APP_BASE_URL || undefined,
});

axiosInstance.interceptors.request.use((req) => {
  if (req.url.includes("/api/v1.0/") || req.url.includes("/api/enrollment")) {
    req.headers.Authorization = `Bearer ${localStorage.getItem("auth_token")}`;
  }
  if (req.url.includes("s3.amazonaws.com")) {
    delete req.headers.common.Authorization;
    delete req.headers.Authorization;
  }
  return req;
});

axiosInstance.interceptors.response.use(
  (res) =>
    new Promise((resolve) => {
      resolve(res);
    }),
  (error) => {
    console.error(error);
    if (!error.response) {
      return new Promise((_, reject) => {
        reject(error);
      });
    }
    if (
      error.response.status === 401 &&
      error.request.responseURL !==
        `${window.location.origin}/api/user/login` &&
      error.request.responseURL !==
        `${window.location.origin}/api/user/pin-login` &&
      error.request.responseURL !==
        `${window.location.origin}/api/user/register_app_user` &&
      window.location.pathname !== "/verify-code" &&
      !error.request.responseURL.includes("/api/enrollment")
    ) {
      Cookies.remove("user");
      localStorage.removeItem("auth_token");
      if (window.location.pathname !== "/") {
        (window as Window).location = "/";
      }
    } else {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  }
);

export default axiosInstance;
