import { useLocation, useNavigate } from "react-router";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import Cookies from "js-cookie";
import useAuth from "hooks/useAuth";
import logo from "../svgIcons/Logo-full.png";
import "../CssStyles/Navbar.css";

const showLogoutButtonPage = ["/additional-info", "/verify-code"];

const Navbar = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { isAuthenticated, dispatch } = useAuth();

  const showLogoutButton = showLogoutButtonPage.includes(pathname) && isAuthenticated;

  const handleOnLogoutClick = () => {
    localStorage.removeItem('auth_token');
    Cookies.remove("user");
    dispatch({ type: "LOGOUT" });
    navigate("/");
  };

  return (
    <div className="navbar">
      <div className="nav-cont">
        <div className="logo-section">
          <div className="logo-text">
            8chili
            <img src={logo} alt="" className="logo-img" />
          </div>
        </div>
        <nav className="nav">
          <ul className="nav-ul">
            <li className="about-tab">
              {showLogoutButton ? (
                <div
                  style={{
                    color: "#F7842D",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    gap: "0.25rem",
                  }}
                  onClick={handleOnLogoutClick}
                >
                  <PowerSettingsNewIcon color="primary" />
                  Logout
                </div>
              ) : (
                <a href="https://www.8chili.com/" className="about">
                  About Us
                </a>
              )}
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
