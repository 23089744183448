import "react-perfect-scrollbar/dist/css/styles.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-quill/dist/quill.snow.css";
import "nprogress/nprogress.css";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { StyledEngineProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ErrorBoundaryFallback, UIKitProvider, dark } from "@8chili/uikit";
import { parseEnvBool } from "@8chili/services";
import { SettingsProvider } from "./contexts/SettingsContext";
import { AuthProvider } from "./contexts/JWTContext";
import App from "./App";

const container = document.getElementById("root")!;
const root = ReactDOM.createRoot(container);

// To make is accessible to other components, not creating Context for something so small.
window["__DEBUG_MODE__"] = parseEnvBool(import.meta.env.VITE_APP_DEBUG_MODE);

root.render(
    <HelmetProvider>
      <StyledEngineProvider injectFirst>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <SettingsProvider>
            <BrowserRouter>
              <AuthProvider>
                <UIKitProvider theme={dark}>
                  <App />
                </UIKitProvider>
              </AuthProvider>
            </BrowserRouter>
          </SettingsProvider>
        </LocalizationProvider>
      </StyledEngineProvider>
    </HelmetProvider>
);

