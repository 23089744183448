import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

export const AlertInfo: React.FC<React.PropsWithChildren<SvgProps>> = (
  props
) => (
  <Svg viewBox="0 0 24 24" {...props}>
    <ellipse cx="11.8931" cy="12" rx="11.8931" ry="12" fill="#00008B" />
    <path
      d="M10.9783 14.693C10.9783 15.3695 11.4929 15.9231 12.1218 15.9231C12.7508 15.9231 13.2654 15.3698 13.2654 14.6933V6.76852C13.2654 6.09202 12.7508 5.53845 12.1218 5.53845C11.4932 5.53845 10.9785 6.09202 10.9783 6.76852V14.693Z"
      fill="white"
    />
    <path
      d="M10.9783 18.1484C10.9783 18.8282 11.493 19.3846 12.1218 19.3846C12.7507 19.3846 13.2654 18.8282 13.2654 18.1484C13.2654 17.4685 12.7507 16.9121 12.1218 16.9121C11.493 16.9121 10.9783 17.4685 10.9783 18.1484Z"
      fill="white"
    />
  </Svg>
);

export default AlertInfo;
