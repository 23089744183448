import styled from "@emotion/styled";
import { space, layout, typography, compose } from "styled-system";
import { TextProps } from "./types";
import { Theme } from "@emotion/react";
import getThemeValue from "../../util/getThemeValue";

interface ThemedProps extends TextProps {
  theme: Theme;
}

const getColor = ({ color, theme }: ThemedProps) => {
  return getThemeValue(theme, `colors.${color}`, color);
};

export const Text = styled.div<TextProps>`
  color: ${getColor};
  font-weight: ${(props) => (props.bold ? 600 : 400)};
  line-height: 1.12;
  ${({ textTransform }) =>
    textTransform ? `text-transform: ${textTransform}` : ""};
  ${({ ellipsis }) =>
    ellipsis &&
    `white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;`}

  ${compose(space, layout, typography)};
`;

export default Text;
