import {
  NavLink as RouterLink,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useContext, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Collapse,
  Divider,
  ListItem,
  Popover,
  Tooltip,
} from "@mui/material";
import { HoverBox } from "./style";
import Confirmation from "../pages/videos/Confirmation";
import ChevronRightIcon from "../icons/ChevronRight";
import ChevronDownIcon from "../icons/ChevronDown";
import VideoContext from "../contexts/video";
import { apiService } from "@8chili/services";

const NavItem = (props) => {
  const {
    active,
    children,
    icon,
    info,
    open: openProp,
    path,
    title,
    ...other
  } = props;
  const [open, setOpen] = useState(openProp);
  const navigate = useNavigate();
  const location = useLocation();
  const [subMenu, setSubMenu] = useState(false);
  const anchorRef = useRef(null);
  const { recordingDetails, lectureDetails, recordVoice } =
    useContext(VideoContext);
  const [openConfirmEditing, setopenConfirmEditing] = useState(false);
  const lecture_id = localStorage.getItem("lecture_id");
  const contentTab = localStorage.getItem("contentTab");

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleOpen = () => {
    setSubMenu(true);
  };

  const handleClose = () => {
    setSubMenu(false);
  };

  const handleOpenConfirmEditing = () => {
    setopenConfirmEditing(true);
  };

  const handleCloseConfirmation = () => {
    setopenConfirmEditing(false);
  };

  const onConfirmEditing = async () => {
    await apiService
      .getData(`/api/video/recording/${recordingDetails.id}/done_edits`)
      .then(() => {
        setopenConfirmEditing(false);
        navigate("/dashboard/procedure-detail", {
          state: {
            id: lecture_id,
            tab: contentTab,
            recordingData: recordingDetails,
            lectureData: lectureDetails,
          },
        });
        window.location.reload();
      });
  };

  const handleEditProgress = () => {
    setopenConfirmEditing(false);
  };

  // Branch
  if (children) {
    return (
      <ListItem
        disableGutters
        sx={{
          display: "block",
          py: 0,
        }}
        {...other}
      >
        <Tooltip title={title} placement="right">
          <Button
            endIcon={
              !open ? (
                <ChevronRightIcon fontSize="small" />
              ) : (
                <ChevronDownIcon fontSize="small" />
              )
            }
            onClick={handleToggle}
            startIcon={icon}
            sx={{
              color: "text.secondary",
              fontWeight: "fontWeightMedium",
              justifyContent: "flex-start",
              pl: "15px",
              pr: "8px",
              py: "12px",
              textAlign: "left",
              textTransform: "none",
              width: "100%",
            }}
            variant="text"
          >
            {info}
          </Button>
        </Tooltip>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  }

  const handleNavItemClick = (e, navTitle) => {
    if (navTitle === "Segments") {
      window.location.reload();
    }
  };

  const routeChange = (e, route) => {
    e.preventDefault();

    navigate(route, {
      state: {
        prevState: location?.pathname,
        tab: "draftLecture",
      },
    });
  };
  // Leaf
  return (
    <>
      {!path ? (
        <>
          <ListItem
            onClick={handleOpen}
            ref={anchorRef}
            disableGutters
            sx={{
              display: "flex",
              marginTop: "20px",
              paddingTop: "10px",
              paddingBottom: "11px",
              height: "71px !important",
              position: "relative",
              backgroundColor: subMenu ? "#262626" : "transparent",
            }}
          >
            <Tooltip title={title} placement="right">
              <Button
                component={path && RouterLink}
                startIcon={icon}
                sx={{
                  color: "text.secondary",
                  fontWeight: "fontWeightMedium",
                  justifyContent: "flex-start",
                  textAlign: "left",
                  pl: "15px",
                  pr: "8px",
                  py: "12px",
                  textTransform: "none",
                  width: "100%",
                  ...(active && {
                    color: "primary.main",
                    fontWeight: "fontWeightBold",
                    "& svg": {
                      color: "primary.main",
                    },
                  }),
                }}
                variant="text"
                to={path}
              >
                {info}
              </Button>
            </Tooltip>
          </ListItem>
          <Popover
            anchorEl={anchorRef.current}
            anchorOrigin={{
              horizontal: "center",
              vertical: "bottom",
            }}
            keepMounted
            className="sub__dropdown"
            onClose={handleClose}
            open={subMenu}
            PaperProps={{
              sx: {
                backgroundColor: "#262626 !important",
                width: 150,
                borderBottomRightRadius: "15px",
                borderBottomLeftRadius: "15px",
                borderTopRightRadius: "15px",
                borderTopLeftRadius: "0px",
                top: "86px !important",
                left: "71px !important",
                boxShadow: "none",
              },
            }}
          >
            <HoverBox>
              <a
                href="/create/course"
                style={{ marginBottom: "17px" }}
                onClick={(e) => routeChange(e, "/create/lecture")}
              >
                New Lecture
              </a>
              <Divider />
              <a
                href="/create/course"
                style={{ marginTop: "16px" }}
                onClick={(e) => routeChange(e, "/create/course")}
              >
                New Course
              </a>
            </HoverBox>
          </Popover>
        </>
      ) : path &&
        (recordingDetails?.editing_status === "request_editing" ||
          recordingDetails?.editing_status === "editing") ? (
        <>
          <ListItem
            onClick={handleOpenConfirmEditing}
            disableGutters
            sx={{
              display: "flex",
              py: 0,
            }}
          >
            <Tooltip title={title} placement="right">
              <Button
                startIcon={icon}
                sx={{
                  color: "text.secondary",
                  fontWeight: "fontWeightMedium",
                  justifyContent: "flex-start",
                  textAlign: "left",
                  pl: "15px",
                  pr: "8px",
                  py: "12px",
                  textTransform: "none",
                  width: "100%",
                  ...(active && {
                    color: "primary.main",
                    fontWeight: "fontWeightBold",
                    "& svg": {
                      color: "primary.main",
                    },
                  }),
                }}
                variant="text"
              >
                {info}
              </Button>
            </Tooltip>
          </ListItem>
          {openConfirmEditing && (
            <Confirmation
              openconfirm={openConfirmEditing}
              heading="Editing in progress"
              bodyText="Video editing is in progress. It will be available to you in a while. "
              close={handleCloseConfirmation}
              onAction={handleEditProgress}
              cancelBtn={null}
              actionBtnName="Ok"
            />
          )}
        </>
      ) : title === "Cut" &&
        recordingDetails?.editing_status !== "complete_editing" ? (
        <>
          <ListItem
            disableGutters
            sx={{
              display: "flex",
              py: 0,
            }}
          >
            <Tooltip title={title} placement="right">
              <Button
                component={path && RouterLink}
                startIcon={icon}
                sx={{
                  color: "text.secondary",
                  fontWeight: "fontWeightMedium",
                  justifyContent: "flex-start",
                  textAlign: "left",
                  pl: "15px",
                  pr: "8px",
                  py: "12px",
                  textTransform: "none",
                  width: "100%",
                  ...(active && {
                    color: "primary.main",
                    fontWeight: "fontWeightBold",
                    "& svg": {
                      color: "primary.main",
                    },
                  }),
                }}
                variant="text"
                to={path}
              >
                {info}
              </Button>
            </Tooltip>
          </ListItem>
        </>
      ) : (title === "Annotation" || title === "Segments") &&
        (recordingDetails?.editing_status === null ||
          recordingDetails?.editing_status === "edited") ? (
        <>
          <ListItem
            onClick={handleOpenConfirmEditing}
            disableGutters
            sx={{
              display: "flex",
              py: 0,
            }}
          >
            <Tooltip title={title} placement="right">
              <Button
                startIcon={icon}
                sx={{
                  color: "text.secondary",
                  fontWeight: "fontWeightMedium",
                  justifyContent: "flex-start",
                  textAlign: "left",
                  pl: "15px",
                  pr: "8px",
                  py: "12px",
                  textTransform: "none",
                  width: "100%",
                  ...(active && {
                    color: "primary.main",
                    fontWeight: "fontWeightBold",
                    "& svg": {
                      color: "primary.main",
                    },
                  }),
                }}
                variant="text"
              >
                {info}
              </Button>
            </Tooltip>
          </ListItem>
          {openConfirmEditing && (
            <Confirmation
              openconfirm={openConfirmEditing}
              heading="Confirm Editing"
              bodyText="Are you sure, you are done with the editing in the video."
              close={handleCloseConfirmation}
              onAction={onConfirmEditing}
              cancelBtn="Cancel"
              actionBtnName="Confirm"
            />
          )}
        </>
      ) : (title === "Annotation" || title === "Segments") &&
        recordingDetails?.editing_status === "failed_editing" ? (
        <>
          <ListItem
            onClick={handleOpenConfirmEditing}
            disableGutters
            sx={{
              display: "flex",
              py: 0,
            }}
          >
            <Tooltip title={title} placement="right">
              <Button
                startIcon={icon}
                sx={{
                  color: "text.secondary",
                  fontWeight: "fontWeightMedium",
                  justifyContent: "flex-start",
                  textAlign: "left",
                  pl: "15px",
                  pr: "8px",
                  py: "12px",
                  textTransform: "none",
                  width: "100%",
                  ...(active && {
                    color: "primary.main",
                    fontWeight: "fontWeightBold",
                    "& svg": {
                      color: "primary.main",
                    },
                  }),
                }}
                variant="text"
              >
                {info}
              </Button>
            </Tooltip>
          </ListItem>
          {openConfirmEditing && (
            <Confirmation
              openconfirm={openConfirmEditing}
              heading="Editing Failed"
              bodyText={
                "Applied cut intervals has failed, please report this error at 'admin@8chili.com'"
              }
              close={handleCloseConfirmation}
              onAction={handleEditProgress}
              cancelBtn={null}
              actionBtnName="Ok"
            />
          )}
        </>
      ) : (
        <>
          <ListItem
            disableGutters
            sx={{
              display: "flex",
              py: 0,
            }}
            onClick={(e) => handleNavItemClick(e, title)}
          >
            <Tooltip title={title} placement="right">
              <Button
                component={path && RouterLink}
                startIcon={icon}
                sx={{
                  color: "text.secondary",
                  fontWeight: "fontWeightMedium",
                  justifyContent: "flex-start",
                  textAlign: "left",
                  pl: "15px",
                  pr: "8px",
                  py: "12px",
                  textTransform: "none",
                  width: "100%",
                  ...(active && {
                    color: "primary.main",
                    fontWeight: "fontWeightBold",
                    "& svg": {
                      color: "primary.main",
                    },
                  }),
                }}
                variant="text"
                to={path}
                disabled={
                  !!(
                    (title === "Cut" &&
                      recordingDetails?.editing_status ===
                        "complete_editing") ||
                    recordVoice ||
                    recordingDetails?.segmentation_status ===
                      "ready_to_process" ||
                    recordingDetails?.segmentation_status === "processing"
                  )
                }
              >
                {info}
              </Button>
            </Tooltip>
          </ListItem>
        </>
      )}
    </>
  );
};

NavItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  depth: PropTypes.number.isRequired,
  icon: PropTypes.node,
  info: PropTypes.node,
  open: PropTypes.bool,
  path: PropTypes.string,
  title: PropTypes.string.isRequired,
};

NavItem.defaultProps = {
  active: false,
  open: false,
};

export default NavItem;
