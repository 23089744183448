import { Helmet } from "react-helmet-async";
import { Box, Typography } from "@mui/material";
import { Policy } from "./style";
import Navbar from "./Navbar";
import Footer from "../pages/authentication/Footer";

function PrivacyPolicy() {
  return (
    <>
      <Helmet>
        <title>Privacy Policy</title>
      </Helmet>
      <Box className="wrapper login-main">
        <Navbar />
        <Box className="section">
          <Policy>
            <Typography color="textPrimary" variant="h3">
              Privacy Policy
            </Typography>
            <Box className="text_heading">
              <Typography fontSize="16px" className="content">
                Last updated: 10/15/2021
                <br />
                <br />
                8chili, Inc. ("us", "we", or "our") operates
                http://www.8chili.com(the "Site"). This page informs you of our
                policies regarding the collection, use and disclosure of
                Personal Information we receive from users of the Site.
                <br />
                We use your Personal Information only for providing and
                improving the Site. By using the Site, you agree to the
                collection and use of information in accordance with this
                policy.
                <br />
                <Typography
                  color="textPrimary"
                  variant="h5"
                  className="text_heading"
                >
                  Information Collection And Use
                </Typography>
                While using our Site, we may ask you to provide us with certain
                personally identifiable information that can be used to contact
                or identify you. Personally identifiable information may
                include, but is not limited to your name ("Personal
                Information").
                <Typography
                  color="textPrimary"
                  variant="h5"
                  className="text_heading"
                >
                  Log Data
                </Typography>
                Like many site operators, we collect information that your
                browser sends whenever you visit our Site ("Log Data"). <br />
                This Log Data may include information such as your computer's
                Internet Protocol ("IP") address, browser type, browser version,
                the pages of our Site that you visit, the time and date of your
                visit, the time spent on those pages and other statistics.{" "}
                <br /> In addition, we may use third party services such as
                Google Analytics that collect, monitor and analyze your
                activities on the Site. <br />
                <br />
                <Typography
                  color="textPrimary"
                  variant="h5"
                  className="text_heading"
                >
                  Communications
                </Typography>
                We may use your Personal Information to contact you with
                newsletters, marketing or promotional materials. <br />
                <br />
                <Typography
                  color="textPrimary"
                  variant="h5"
                  className="text_heading"
                >
                  Cookies
                </Typography>
                Cookies are files with a small amount of data, which may include
                an anonymous unique identifier. Cookies are sent to your browser
                from a web site and stored on your computer's hard drive.
                <br /> Like many sites, we use "cookies" to collect information.
                You can instruct your browser to refuse all cookies or to
                indicate when a cookie is being sent. However, if you do not
                accept cookies, you may not be able to use some portions of our
                Site. <br />
                <br />
                <Typography
                  color="textPrimary"
                  variant="h5"
                  className="text_heading"
                >
                  Security
                </Typography>
                The security of your Personal Information is important to us,
                but remember that no method of transmission over the Internet,
                or method of electronic storage, is 100% secure. While we strive
                to use commercially acceptable means to protect your Personal
                Information, we cannot guarantee its absolute security. <br />
                <br />
                <Typography
                  color="textPrimary"
                  variant="h5"
                  className="text_heading"
                >
                  Changes To This Privacy Policy
                </Typography>
                This Privacy Policy is effective as of 10/15/2021 and will
                remain in effect except with respect to any changes in its
                provisions in the future, which will be in effect immediately
                after being posted on this page. <br />
                We reserve the right to update or change our Privacy Policy at
                any time and you should check this Privacy Policy periodically.
                Your continued use of the Service after we post any
                modifications to the Privacy Policy on this page will constitute
                your acknowledgment of the modifications and your consent to
                abide and be bound by the modified Privacy Policy. <br />
                If we make any material changes to this Privacy Policy, we will
                notify you either through the email address you have provided
                us, or by placing a prominent notice on our website. <br />
                <br />
                <Typography
                  color="textPrimary"
                  variant="h5"
                  className="text_heading"
                >
                  Contact Us
                </Typography>
                If you have any questions about this Privacy Policy, please
                contact us at hello@8chili.com.
              </Typography>
            </Box>
          </Policy>
        </Box>
        <Footer />
      </Box>
    </>
  );
}

export default PrivacyPolicy;
