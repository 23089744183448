import styled from "@emotion/styled";
import { Theme, css } from "@emotion/react";
import { space, layout, variant, margin, MarginProps } from "styled-system";
import { scaleVariants, styleVariants } from "./theme";
import { BaseButtonProps } from "./types";

interface ThemedButtonProps extends BaseButtonProps {
  theme: Theme;
}

const getDisabledStyles = ({ isLoading, theme }: ThemedButtonProps) => {
  if (isLoading === true) {
    return `
      &:disabled,
      &.etchili-button--disabled {
        cursor: not-allowed;
      }
    `;
  }

  return `
    &:disabled,
    &.etchili-button--disabled {
      background-color: ${theme.colors.disabled};
      border-color: ${theme.colors.disabled};
      box-shadow: none;
      color: ${theme.colors.textGrey};
      cursor: not-allowed;
    }
  `;
};

/**
 * This is to get around an issue where if you use a Link component
 * React will throw a invalid DOM attribute error
 * @see https://github.com/styled-components/styled-components/issues/135
 */

const getOpacity = (isLoading: boolean | undefined) => {
  return isLoading ? ".5" : "1";
};

export const StyledButton = styled.button<BaseButtonProps>`
  position: relative;
  align-items: center;
  border: 0;
  border-radius: ${({ theme }) => theme.radii.button};
  box-shadow: 0px -1px 0px 0px rgba(14, 14, 44, 0.4) inset;
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  font-size: 16px;
  color: white;
  font-weight: 500;
  width: 100%;
  justify-content: center;
  letter-spacing: 0.03em;
  line-height: 1;
  opacity: ${(props) => getOpacity(props.isLoading)};
  outline: 0;
  transition: background-color 0.2s, opacity 0.2s;

  :focus-visible {
    outline: none;
    box-shadow: ${({ theme }) => theme.shadows.focus};
  }

  @media (hover: hover) {
    &:hover:not(:disabled):not(.etchili-button--disabled):not(
        .etchili-button--disabled
      ):not(:active) {
      opacity: 0.65;
    }
  }

  &:active:not(:disabled):not(.etchili-button--disabled):not(
      .etchili-button--disabled
    ) {
    opacity: 0.85;
    transform: translateY(1px);
    box-shadow: none;
  }

  ${(props) => getDisabledStyles(props)}
  ${variant({
    prop: "scale",
    variants: scaleVariants,
  })}
  ${variant({
    variants: styleVariants,
  })}
  ${layout}
  ${space}
  ${({ decorator, theme }) =>
    decorator &&
    css`
      &::before {
        content: "${decorator.text}";
        position: absolute;
        border-bottom: 20px solid
          ${decorator.backgroundColor ?? theme.colors.secondary};
        border-left: 34px solid transparent;
        border-right: 12px solid transparent;
        height: 0;
        top: 0;
        right: -12px;
        text-align: center;
        padding-right: 6px;
        line-height: 20px;
        font-size: 12px;
        font-weight: 400;
        transform: rotate(31.17deg);
        color: ${decorator.color ?? "white"};
      }
    `}
`;

export const ButtonAdornmentWrapper = styled.span<MarginProps>`
  ${margin}
`;

export default StyledButton;
