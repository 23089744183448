import { ElementType, HTMLProps, ReactElement, ReactNode } from "react";
import { SpaceProps } from "styled-system";
import { DropDownOptionType } from "../SimpleFormBuilder";

export const radioScales = {
  SM: "sm",
  MD: "md",
} as const;

export const scales = {
  SM: "sm",
  MD: "md",
  LG: "lg",
} as const;

export type Scales = (typeof scales)[keyof typeof scales];
export type RadioScales = (typeof radioScales)[keyof typeof radioScales];

// Inputs does not need error as they have warnings.
export interface BaseInputProps extends SpaceProps {
  scale?: Scales;
  isSuccess?: boolean;
  isWarning?: boolean;
}

export interface TextInputProps
  extends BaseInputProps,
    Omit<HTMLProps<HTMLInputElement>, "as"> {
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  label?: string;
  type?: "text" | "password" | string;
  as?: ElementType<any> | undefined;
}

export interface InputGroupProps extends SpaceProps {
  scale?: Scales;
  startIcon?: ReactElement;
  endIcon?: ReactElement;
  children: JSX.Element;
}

export interface RadioProps extends SpaceProps {
  scale?: RadioScales;
}

export interface SelectInputProps
  extends SpaceProps,
    Omit<HTMLProps<HTMLSelectElement>, "as"> {
  isSuccess?: boolean;
  isWarning?: boolean;
  options?: DropDownOptionType[];
  label?: string;
}
