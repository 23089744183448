import { Link as RouterLink } from "react-router-dom";
import { Box, Link } from "@mui/material";
import { FooterCont } from "../../components/style";

interface FooterProps {
  step?: number;
  type?: "course" | "lecture";
  footerStyle?: React.CSSProperties;
}

const Footer = ({ step, type, footerStyle }: FooterProps) => (
  <FooterCont step={step} type={type} style={footerStyle}>
    <Box className="footer-cont">
      <Box className="copyright-text">
        &#169; 2021 8chili Inc. All rights reserved.
      </Box>
      <nav className="privacy-policy">
        <ul>
          <Link
            color="textSecondary"
            component={RouterLink}
            to="/terms_and_condition"
            underline="none"
            variant="body1"
          >
            Terms of service
          </Link>
          <Link
            color="textSecondary"
            component={RouterLink}
            to="/policy"
            underline="none"
            variant="body1"
          >
            Privacy policy
          </Link>
        </ul>
      </nav>
    </Box>
  </FooterCont>
);

export default Footer;
