import { ReactNode, createContext, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import Cookies from "js-cookie";
import axios from "../utils/axiosInstance";
import { apiService } from "@8chili/services";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  tab_state: 1,
};

const setSession = (
  auth_token: string,
  status?: string,
  msg?: string,
  orgType?: string
) => {
  if (auth_token) {
    localStorage.setItem("auth_token", auth_token);
    localStorage.setItem("status", status);
    localStorage.setItem("msg", msg);
    localStorage.setItem("orgType", orgType);
    axios.defaults.headers.common.Authorization = auth_token;
  } else {
    Cookies.remove("user");
    localStorage.removeItem("auth_token");
    delete axios.defaults.headers.common.Authorization;
  }
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },

  UPDATE_USER_STATUS: (state, action) => {
    const updatedState = {
      ...state,
      user: {
        ...state.user,
        msg: action.payload.msg,
      },
    };

    return { ...updatedState };
  },
  UPDATE_TAB_STATE: (state, action) => {
    const updatedState = {
      ...state,
      tab_state: action.payload,
    };
    return { ...updatedState };
  },

  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  platform: "JWT",
  dispatch: (_: any) => undefined,
  login: (_: any) => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  updateStatus: () => Promise.resolve(),
});

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const auth_token = window.localStorage.getItem("auth_token");
        const org_type = window.localStorage.getItem("orgType");
        if (auth_token) {
          // setSession(auth_token, orgType=);
          const userFromCookie = Cookies.get("user");
          const user = JSON.parse(userFromCookie);

          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (values) => {
    const { email, password } = values;
    await apiService
      .postData("/api/user/login", {
        email,
        password,
      })
      .then((response) => {
        Cookies.set("user", JSON.stringify(response && response.data));
        const { auth_token, status, msg } = response.data;

        setSession(auth_token, status, msg, response.data.org_details.category);
        dispatch({
          type: "LOGIN",
          payload: {
            user: response && response.data,
          },
        });
      })
      .catch((err) => {
        console.error("login error: ", err.response);
      });
  };

  const logout = async () => {
    await apiService
      .get("/api/user/logout")
      .then((res) => {
        if (res) {
          Cookies.remove("user");
          localStorage.removeItem('auth_token');
          dispatch({ type: "LOGOUT" });
        }
      })
      .catch((err) => {
        if (err) {
          dispatch({ type: "LOGOUT" });
        }
      });
  };

  const updateStatus = async () => {
    dispatch({
      type: "UPDATE_USER_STATUS",
      payload: {
        msg: "PendingOrgDetails",
      },
    });
  };

  const register = async (values) => {
    const { name, email, password } = values;
    const response = await axios.post("/api/user/signup", {
      name,
      email,
      password,
    });

    const { auth_token } = response.data;
    Cookies.set("user", JSON.stringify(response && response.data));
    setSession(auth_token);

    dispatch({
      type: "REGISTER",
      payload: {
        user: response && response.data,
      },
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        dispatch,
        platform: "JWT",
        login,
        logout,
        register,
        updateStatus,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
