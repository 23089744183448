import { createContext } from "react";
import { useSnapshot } from "valtio";
import { UserProviderProps } from "./type";
import {
  UserStateType,
  logOutApiCurried,
  loginAPICurried,
  userState,
} from "./user.state";

export const UserContext = createContext<any | UserStateType>(null);

const UserProvider = ({
  children,
  apis: { logInApi, logOutApi },
}: UserProviderProps) => {
  userState.actions.login = loginAPICurried(logInApi);
  userState.actions.logout = logOutApiCurried(logOutApi);

  const user = useSnapshot(userState);

  return (
    <UserContext.Provider value={{ ...user }}>{children}</UserContext.Provider>
  );
};

export default UserProvider;
