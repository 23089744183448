import { ContentTypeEnum } from "../constants/contentEnum";

const DASHBOARD_OBJ = {
  name: "Dashboard",
  toLink: "/dashboard",
};
export const routeNames: Record<
  string,
  { route: string; crumbs?: { name: string; toLink: string }[] }
> = {
  dashboard: {
    route: "/dashboard",
    crumbs: [DASHBOARD_OBJ],
  },
  login: { route: "/login" },
  [ContentTypeEnum.User]: {
    route: "/content/user",
    crumbs: [
      DASHBOARD_OBJ,
      {
        name: "User",
        toLink: "/content/user",
      },
    ],
  },
  [ContentTypeEnum.Creator]: {
    route: "/content/creator",
    crumbs: [
      DASHBOARD_OBJ,
      {
        name: "Creator",
        toLink: "/content/creator",
      },
    ],
  },
  [ContentTypeEnum.Orgs]: {
    route: "/content/org",
    crumbs: [
      DASHBOARD_OBJ,
      {
        name: "Organization",
        toLink: "/content/org",
      },
    ],
  },
  [ContentTypeEnum.Course]: {
    route: "/content/course",
    crumbs: [
      DASHBOARD_OBJ,
      {
        name: "Course",
        toLink: "/content/course",
      },
    ],
  },
  [ContentTypeEnum.Lecture]: {
    route: "/content/lecture",
    crumbs: [
      DASHBOARD_OBJ,
      {
        name: "Lecture",
        toLink: "/content/lecture",
      },
    ],
  },
  [ContentTypeEnum.Recordings]: {
    route: "/content/recording",
    crumbs: [
      DASHBOARD_OBJ,
      {
        name: "Recording",
        toLink: "/content/recording",
      },
    ],
  },
  [ContentTypeEnum.Podcasts]: {
    route: "/content/podcast",
    crumbs: [
      DASHBOARD_OBJ,
      {
        name: "Podcast",
        toLink: "/content/podcast",
      },
    ],
  },
  [ContentTypeEnum.Simulations]: {
    route: "/content/simulation",
    crumbs: [
      DASHBOARD_OBJ,
      {
        name: "Simulation",
        toLink: "/content/simulation",
      },
    ],
  },
};

export default routeNames;
