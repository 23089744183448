import React, { useCallback, useState } from "react";
import { AdditionalInfoContext } from ".";

type AdditionalInfoContextProviderPropsType = {
  children: React.ReactNode;
};

const AdditionalInfoContextProvider = ({ children }: AdditionalInfoContextProviderPropsType) => {
  // this need to be handled somehow else. or this needed to be managed from backend.
  const [currentStep, setCurrentStep] = useState<number>(1);

  const nextStep = useCallback(() => setCurrentStep((step) => step + 1), []);
  const previousStep = useCallback(
    () =>
      setCurrentStep((step) => {
        if (step < 1) return;

        return step - 1;
      }),
    [],
  );

  const updateUserData = useCallback(async () => null, []);

  const updateOrgDetails = useCallback(async () => null, []);

  return (
    <AdditionalInfoContext.Provider
      value={{
        currentStep,
        previousStep,
        nextStep,
        updateUserData,
        updateOrgDetails,
      }}
    >
      {children}
    </AdditionalInfoContext.Provider>
  );
};

export default AdditionalInfoContextProvider;
