import { getDateForNPreviousDays } from "../../../util/dataRenderer";

const TOTAL_NO_OF_DAYS = 7;

export const data = [
  {
    id: "Dental",
    color: "hsl(244, 70%, 50%)",
    data: [
      {
        x: getDateForNPreviousDays(TOTAL_NO_OF_DAYS - 1),
        y: 293,
      },
      {
        x: getDateForNPreviousDays(TOTAL_NO_OF_DAYS - 2),
        y: 224,
      },
      {
        x: getDateForNPreviousDays(TOTAL_NO_OF_DAYS - 3),
        y: 175,
      },
      {
        x: getDateForNPreviousDays(TOTAL_NO_OF_DAYS - 4),
        y: 88,
      },
      {
        x: getDateForNPreviousDays(TOTAL_NO_OF_DAYS - 5),
        y: 49,
      },
      {
        x: getDateForNPreviousDays(TOTAL_NO_OF_DAYS - 6),
        y: 5,
      },
      {
        x: getDateForNPreviousDays(TOTAL_NO_OF_DAYS - 7),
        y: 287,
      },
    ],
  },
  {
    id: "Total Shoulder Replacement",
    color: "hsl(341, 70%, 50%)",
    data: [
      {
        x: getDateForNPreviousDays(TOTAL_NO_OF_DAYS - 1),
        y: 300,
      },
      {
        x: getDateForNPreviousDays(TOTAL_NO_OF_DAYS - 2),
        y: 154,
      },
      {
        x: getDateForNPreviousDays(TOTAL_NO_OF_DAYS - 3),
        y: 198,
      },
      {
        x: getDateForNPreviousDays(TOTAL_NO_OF_DAYS - 4),
        y: 231,
      },
      {
        x: getDateForNPreviousDays(TOTAL_NO_OF_DAYS - 5),
        y: 123,
      },
      {
        x: getDateForNPreviousDays(TOTAL_NO_OF_DAYS - 6),
        y: 268,
      },
      {
        x: getDateForNPreviousDays(TOTAL_NO_OF_DAYS - 7),
        y: 121,
      },
    ],
  },
  {
    id: "Introduction to Cardiology",
    color: "hsl(144, 70%, 50%)",
    data: [
      {
        x: getDateForNPreviousDays(TOTAL_NO_OF_DAYS - 1),
        y: 229,
      },
      {
        x: getDateForNPreviousDays(TOTAL_NO_OF_DAYS - 2),
        y: 187,
      },
      {
        x: getDateForNPreviousDays(TOTAL_NO_OF_DAYS - 3),
        y: 129,
      },
      {
        x: getDateForNPreviousDays(TOTAL_NO_OF_DAYS - 4),
        y: 291,
      },
      {
        x: getDateForNPreviousDays(TOTAL_NO_OF_DAYS - 5),
        y: 129,
      },
      {
        x: getDateForNPreviousDays(TOTAL_NO_OF_DAYS - 6),
        y: 104,
      },
      {
        x: getDateForNPreviousDays(TOTAL_NO_OF_DAYS - 7),
        y: 20,
      },
    ],
  },
  {
    id: "Solar System",
    color: "hsl(334, 70%, 50%)",
    data: [
      {
        x: getDateForNPreviousDays(TOTAL_NO_OF_DAYS - 1),
        y: 105,
      },
      {
        x: getDateForNPreviousDays(TOTAL_NO_OF_DAYS - 2),
        y: 181,
      },
      {
        x: getDateForNPreviousDays(TOTAL_NO_OF_DAYS - 3),
        y: 270,
      },
      {
        x: getDateForNPreviousDays(TOTAL_NO_OF_DAYS - 4),
        y: 147,
      },
      {
        x: getDateForNPreviousDays(TOTAL_NO_OF_DAYS - 5),
        y: 77,
      },
      {
        x: getDateForNPreviousDays(TOTAL_NO_OF_DAYS - 6),
        y: 53,
      },
      {
        x: getDateForNPreviousDays(TOTAL_NO_OF_DAYS - 7),
        y: 7,
      },
    ],
  },
  {
    id: "Spine Surgeries",
    color: "hsl(231, 70%, 50%)",
    data: [
      {
        x: getDateForNPreviousDays(TOTAL_NO_OF_DAYS - 1),
        y: 127,
      },
      {
        x: getDateForNPreviousDays(TOTAL_NO_OF_DAYS - 2),
        y: 153,
      },
      {
        x: getDateForNPreviousDays(TOTAL_NO_OF_DAYS - 3),
        y: 217,
      },
      {
        x: getDateForNPreviousDays(TOTAL_NO_OF_DAYS - 4),
        y: 133,
      },
      {
        x: getDateForNPreviousDays(TOTAL_NO_OF_DAYS - 5),
        y: 275,
      },
      {
        x: getDateForNPreviousDays(TOTAL_NO_OF_DAYS - 6),
        y: 262,
      },
      {
        x: getDateForNPreviousDays(TOTAL_NO_OF_DAYS - 7),
        y: 171,
      },
    ],
  },
];
