import { useNavigate } from "react-router-dom";
import { useRef, useState } from "react";
import { Avatar, Box, ButtonBase, Popover } from "@mui/material";
import ChevronUpIcon from "../../icons/ChevronUp";
import ChevronDownIcon from "../../icons/ChevronDown";
import useAuth from "../../hooks/useAuth";
import DropDown from "../dropdowns/ProfileDropDown";
import { useToast } from "@8chili/uikit";

const AccountPopover = () => {
  const anchorRef = useRef(null);
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { toastError } = useToast();

  const arrowSpace = {
    color: "rgba(255, 255, 255, 0.4)",
    marginLeft: "10px",
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await logout();
      navigate("/");
    } catch (err) {
      console.error(err);
      toastError("Unable to logout.");
    }
  };

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: "center",
          display: "flex",
        }}
      >
        <Avatar
          src={user && user.avatar}
          sx={{
            height: 32,
            width: 32,
          }}
        />
        {open ? (
          <ChevronUpIcon fontSize="small" style={arrowSpace} />
        ) : (
          <ChevronDownIcon style={arrowSpace} fontSize="small" />
        )}
      </Box>

      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        keepMounted
        className="porfile__dropdown"
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 210 },
        }}
      >
        <DropDown handleClose={handleClose} handleLogout={handleLogout} />
      </Popover>
    </>
  );
};

export default AccountPopover;
