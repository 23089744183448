import { Outlet } from "react-router-dom";
import { useCallback, useContext, useEffect, useState } from "react";
import { experimentalStyled } from "@mui/material";
import { apiService } from "@8chili/services";
import VideoSectionFooter from "./video/Footer";
import TimelineHeader from "./TimelineHeader";
import { ENDTIME_SECS } from "../pages/videos/Annotations";
import VideoContext from "../contexts/video";

const TimelineLayouttRoot = experimentalStyled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,

  height: "100%",
  width: "100%",
}));

const TimelineLayoutWrapper = experimentalStyled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
  [theme.breakpoints.up("lg")]: {
    paddingLeft: "20px",
    paddingRight: "20px",
  },
}));

const TimelineLayoutContainer = experimentalStyled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
});

const TimelineLayoutContent = experimentalStyled("div")({
  flex: "1 1 auto",
  height: "100%",
  overflow: "auto",
  position: "relative",
  WebkitOverflowScrolling: "touch",
});

const TimelineLayout = () => {
  const {
    videoDispatcher,
    lectureDetails,
    recordingDetails,
    orignalVideoDuration,
  } = useContext(VideoContext);
  const [loading, setLoading] = useState(false);
  const recordingId = localStorage?.getItem("recording_id");
  const [_, setrecordingData] = useState([]);

  const getLectureDetail = useCallback(async () => {
    const lecture_id = localStorage.getItem("lecture_id");
    setLoading(true);

    try {
      const res = await apiService.getData(
        `/api/v2.0/lecture/detail/${lecture_id}`
      );

      videoDispatcher({
        type: "SET_LECTURE_DETAILS",
        payload: {
          ...res.data,

          cut_intervals:
            res.data.cut_intervals?.map(([startTime, endTime], index) => ({
              startTime,
              endTime,
              index: String(index),
            })) || [],
          annotations:
            res.data.annotations?.map((annotate) => ({
              ...annotate,
              startTime: annotate.play_time,
              endTime: annotate?.pause_duration
                ? annotate.play_time + annotate.pause_duration
                : annotate.play_time + ENDTIME_SECS,
              type: annotate.type === "text" ? "SPEECH" : "REGION",
              description: annotate.name,
            })) || [],
          color_corrections:
            res.data.color_corrections?.map((correction) => ({
              ...correction,
              startTime: correction.start,
              endTime: correction.end,
              value: correction.corrections,
            })) || [],
          voices: res.data.segments?.map((voice) => ({
            ...voice,
            startTime: voice?.start_time || 0,
            endTime: voice?.end_time || 0,
            audio: voice?.thumbnail,
            type: voice?.name,
            id: voice?.id,
          })),
        },
      });
      videoDispatcher({
        type: "UPDATE_VIDEO_DURATION",
        payload: res.data.end_time || 0,
      });
      if (res.data.start_time !== null && res.data.end_time !== null) {
        videoDispatcher({
          type: "UPDATE_TRIM_HISTORY",
          payload: {
            startTime: res.data?.start_time,
            endTime: res.data?.end_time,
          },
        });
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }, [videoDispatcher]);

  const getRecordingDetail = useCallback(async () => {
    try {
      const recRes = await apiService.getData(
        `/api/video/recording_details/${recordingId}`
      );
      const recData = recRes?.data?.details;
      setrecordingData(recData);
      videoDispatcher({
        type: "SET_RECORDING_DETAILS",
        payload: {
          ...recRes.data.details,

          cut_intervals:
            recData?.cut_intervals?.map(([startTime, endTime], index) => ({
              startTime,
              endTime,
              index: String(index),
            })) || [],
          annotations:
            recData?.annotations?.map((annotate) => ({
              ...annotate,
              startTime: annotate.play_time,
              endTime: annotate?.pause_duration
                ? annotate.play_time + annotate.pause_duration
                : annotate.play_time + ENDTIME_SECS,
              // endTime: annotate.play_time + ENDTIME_SECS,
              type: annotate.type === "text" ? "SPEECH" : "REGION",
              description: annotate.name,
              width: annotate?.width,
              height: annotate?.height,
            })) || [],
          color_corrections:
            recData?.color_corrections?.map((correction) => ({
              ...correction,
              startTime: correction.start,
              endTime: correction.end,
              value: correction.corrections,
            })) || [],

          voices: recData?.segments?.map((voice) => ({
            ...voice,
            startTime: voice?.start_time || 0,
            endTime: voice?.end_time || 0,
            audio: voice?.audio_url,
            type: voice?.name,
            title: voice?.name,
            status: voice?.id,
            id: voice?.id,
          })),
        },
      });
      videoDispatcher({
        type: "UPDATE_VIDEO_DURATION",
        payload: recData?.end_time || 0,
      });

      if (recData.start_time !== null && recData.end_time !== null) {
        videoDispatcher({
          type: "UPDATE_TRIM_HISTORY",
          payload: {
            startTime: recData?.start_time,
            endTime: recData?.end_time,
          },
        });
      }
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    if (recordingId) {
      videoDispatcher({
        type: "UPDATE_TRIM_TIMER",
        payload: {
          startTime: recordingDetails?.start_time || 0,
          endTime: recordingDetails?.end_time || orignalVideoDuration,
        },
      });

      videoDispatcher({
        type: "UPDATE_CUT_HISTORY",
        payload: recordingDetails?.cut_intervals || [],
      });

      videoDispatcher({
        type: "UPDATE_ANNOTE_HISTORY",
        payload: recordingDetails?.annotations || [],
      });

      videoDispatcher({
        type: "UPDATE_COLOR_HISTORY",
        payload: recordingDetails?.color_corrections || [],
      });
      videoDispatcher({
        type: "ADD_VOICE",
        payload: recordingDetails?.voices || [],
      });
    }
  }, [lectureDetails, orignalVideoDuration, videoDispatcher, recordingDetails]);

  useEffect(() => {
    getLectureDetail();
    getRecordingDetail();
  }, [getLectureDetail]);

  if (loading) {
    return <>Loading</>;
  }

  return (
    <TimelineLayouttRoot>
      <TimelineHeader recordingDetails={recordingDetails} />
      <TimelineLayoutWrapper>
        <TimelineLayoutContainer>
          <TimelineLayoutContent>
            <Outlet />
          </TimelineLayoutContent>
        </TimelineLayoutContainer>
      </TimelineLayoutWrapper>
      <VideoSectionFooter />
    </TimelineLayouttRoot>
  );
};

export default TimelineLayout;
