import Styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import PropTypes from "prop-types";
import { experimentalStyled } from "@mui/material";
import {
  AppBar,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";

import MenuIcon from "../icons/Menu";

import VideoContext from "../contexts/video";

const VideoNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === "light" && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: "none",
    color: theme.palette.text.primary,
  }),
  ...(theme.palette.mode === "dark" && {
    backgroundColor: theme.palette.background.default,
    boxShadow: "none",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "98%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "95%",
    },
  }),
  zIndex: theme.zIndex.drawer + 100,
}));

const Heading = Styled.div`

h2 {
  font-family: 'Mulish', sans-serif;
  font-size:22px;
  font-weight: 600;
  line-height: 28px;
  text-align : left;

  @media (max-width: 900px) {
    flex-basis: 40%;
    flex-grow: 0;
    max-width: 40%;
 }

  @media(max-width:831px) {
     font-size:20px;
  }

  @media(max-width:767px) {
      display:none;
  }
}

`;

const TimeDuration = Styled.div`
h2 {
    font-weight: 600;
    font-size: 15px;
    font-family: 'Mulish', sans-serif;
    line-height: 19px;
    color: rgba(255, 255, 255, 0.6);
    display: flex;
    justify-content: end;
    @media (max-width: 460px){
        display: none;
    }

    span {
      font-family: 'Mulish', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 19px;
        color: rgba(255, 255, 255, 0.9);
        margin-right : 15px
    }
}

`;

const Preview = Styled.div`
// margin-right:13px;
button {
    background: #FF852C;
    border-radius: 5px;
    box-sizing: border-box;
    border-radius: 5px;
    width:122px;
    height:39px;
    margin-left :10px;
    h6 {
        font-weight: normal;
        font-size: 15px;
        line-height: 19px;
        color : #fff !important;
        text-align: center;
    };
}
`;

export const getFormattedTextFromSec = (secs, cut, trim) => {
  const secNum = parseInt(secs.toString(), 10);
  const trimDuration = trim.endTime - trim.startTime;
  const cutSegment = cut.history?.map(
    (cutItem) => cutItem.endTime - cutItem.startTime
  );
  let totalCut = 0;
  for (let i = 0; i < cutSegment.length; i++) {
    totalCut += cutSegment[i];
  }
  let totalDuration = 0;
  if (trimDuration === 0) {
    totalDuration = totalCut;
  } else {
    totalDuration = trimDuration - totalCut;
  }

  const duration = totalDuration || secNum;

  let hours: number | string = Math.floor(duration / 3600);
  if (hours < 10) {
    hours = `0${hours}`;
  }
  const minutes = Math.floor(duration / 60) % 60;
  const seconds = duration % 60;

  const roundSec = Math.round(seconds);

  let text = `${roundSec}s`;

  if (minutes && !Number(hours)) {
    text = `${minutes}m ${text}`;
  } else if (minutes && Number(hours)) {
    text = `${hours}h ${minutes}m ${text}`;
  }

  return text;
};

export const checkIsCutTrim = (cut, trim, annote) => {
  if (
    trim?.history?.length ||
    cut?.history?.length ||
    annote?.history?.length
  ) {
    return true;
  }

  if (
    !trim?.history?.length &&
    !cut?.history?.length &&
    annote?.history?.length
  ) {
    return false;
  }
};

const StyledContainer = experimentalStyled(Container)(() => ({
  "@media (max-width: 1279px)": {
    paddingLeft: "5px",
  },
  "@media (min-width: 1280px) and (max-width: 1740px)": {
    paddingLeft: "24px",
  },
  "@media (min-width: 1741px) ": {
    paddingLeft: "10px",
  },
}));

const VideoNavbar = (props) => {
  const {
    onSidebarMobileOpen,
    title,
    lectureData,
    recordingData,
    getRecordingsData,
  } = props;
  const { videoDuration, cut, trim, recordingDetails, recordVoice } =
    useContext(VideoContext);
  const formattedDuration = getFormattedTextFromSec(videoDuration, cut, trim);
  const navigate = useNavigate();
  const lecture_id = localStorage.getItem("lecture_id");
  const contentTab = localStorage.getItem("contentTab");

  const handleClickPreview = () => {
    navigate(`/dashboard/procedure-detail`, {
      state: {
        id: lecture_id,
        tab: contentTab,
        recordingData,
        lectureData,
      },
    });
    getRecordingsData();
  };

  return (
    <VideoNavbarRoot>
      <StyledContainer
        sx={{
          mt: 3,
          ml: 0,
          p: 0,
        }}
        style={{ paddingRight: 18 }}
        maxWidth="xl"
      >
        <Grid item lg={12} xs={12} md={12} style={{ padding: 0 }}>
          <Box
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Grid
              item
              sx={{
                display: {
                  lg: "none",
                },
              }}
              xs={1}
              sm={1}
              md={1}
              style={{ padding: 0 }}
            >
              <IconButton
                color="inherit"
                onClick={onSidebarMobileOpen}
                sx={{
                  display: {
                    lg: "none",
                  },
                }}
              >
                <MenuIcon fontSize="small" />
              </IconButton>
            </Grid>
            <Grid item lg={7} sm={3} md={6} style={{ padding: 0 }}>
              {" "}
              <Heading>
                <Typography
                  style={{ textTransform: "capitalize" }}
                  align="left"
                  color="textPrimary"
                  variant="h2"
                >
                  {recordingDetails?.name}
                </Typography>
              </Heading>
            </Grid>

            <Grid
              item
              lg={5}
              xs={11}
              sm={8}
              md={5}
              style={{
                padding: 0,
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <TimeDuration>
                <Typography color="textPrimary" variant="h2">
                  Duration : <span>{formattedDuration}</span>
                </Typography>
              </TimeDuration>
              {!title ? (
                <Preview>
                  <Button disabled={!!recordVoice} onClick={handleClickPreview}>
                    <Typography color="textPrimary" variant="h6">
                      Preview
                    </Typography>
                  </Button>
                </Preview>
              ) : null}
            </Grid>
          </Box>
        </Grid>
        <Grid item lg={6} md={12} xs={12} style={{ padding: 0 }} />
      </StyledContainer>
    </VideoNavbarRoot>
  );
};

VideoNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
};

export default VideoNavbar;
