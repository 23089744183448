import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

export const AlertAdded: React.FC<React.PropsWithChildren<SvgProps>> = (
  props
) => (
  <Svg viewBox="7 4 22 28" {...props}>
    <path
      d="M7.5 6.48895C7.5 5.39048 8.29398 4.5 9.2734 4.5H16.2266C17.206 4.5 18 5.39048 18 6.48895V13.011C18 14.1095 18.794 15 19.7734 15H26.7266C27.706 15 28.5 15.8905 28.5 16.989V23.25V29.511C28.5 30.6095 27.706 31.5 26.7266 31.5H9.2734C8.29398 31.5 7.5 30.6095 7.5 29.511V6.48895Z"
      fill="#0083FC"
    />
    <path
      d="M19.5 12.5055V6.71908C19.5 5.86028 20.4053 5.40527 20.9768 5.97681L26.7633 11.7633C27.3713 12.3713 26.9878 13.5 26.1732 13.5H20.3867C19.897 13.5 19.5 13.0548 19.5 12.5055Z"
      fill="#0083FC"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18 16.2555C18.4897 16.2555 18.8867 16.7007 18.8867 17.25V26.25C18.8867 26.7992 18.4897 27.2444 18 27.2444C17.5103 27.2444 17.1133 26.7992 17.1133 26.25V17.25C17.1133 16.7007 17.5103 16.2555 18 16.2555Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.4359 21.75C12.4359 21.0909 12.9123 20.5566 13.5 20.5566H22.5C23.0876 20.5566 23.564 21.0909 23.564 21.75C23.564 22.4091 23.0876 22.9434 22.5 22.9434H13.5C12.9123 22.9434 12.4359 22.4091 12.4359 21.75Z"
      fill="white"
    />
  </Svg>
);

export default AlertAdded;
