import Styled from "styled-components";

const HeadWrapper = Styled.div`
        margin-top:26px;
        margin-bottom:46px;
        position:relative;
        

        button {
            position:absolute;
            top:0;
            right:0;
        }
`;
const TabPanels = Styled.div`
max-width: 80%;

input {
    width: 100%;   
    text-align: left;
    padding:0 10px ;
    height: 42px;
}
.MuiTabs-flexContainer{
}
.MuiTab-root{
    min-width: 70px;
    color: rgba(255,-w255, 255, 0.5);
    font-size: 14px;
    font-weight: 600; 
    text-transform: none;
    padding: 0;
    
}
.Mui-selected{color: #FFFFFF !important;}
.MuiTabs-indicator {
    background-color: #FFFFFF;
}
`;

const TextFieldInput = Styled.div`

.MuiOutlinedInput-input{
    width:100%;
    text-align:left;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 5px;
    padding:0 15px;
}
`;
const EarningDetail = Styled.div`
        padding-bottom:16px;
        border-bottom: 0.56982px solid rgba(255, 255, 255, 0.2);
        margin-bottom:15px;
        display:flex;
        justify-content:space-between;

        h1 {
            font-weight: normal;
            font-size: 16px;
        }

        h2 {
            font-weight: normal;
            font-size: 16px;
        }

       


`;

const HoverEffect = Styled.div`
a {

    .MuiTouchRipple-root {
        display:none;
    }
    &:hover {
        background:transparent;
        color:#FF852C;

    }
}



`;

const LayoutWrapper = Styled.div`
margin:35px 25px 0px 25px;
img {
    object-fit:cover;
}
    
h2 {
    font-weight: 600;
    font-size: 26px;
    margin:7px 0px 0px 0px;
    margin-left:15px;
}

.actions {

    a {
        height:39px;
        width:145px;
        background: rgba(255, 133, 44, 0.15);
        margin-right:15px;

        &:last-child {
            width:39px;
            height:39px;
            background: rgba(255, 255, 255, 0.15);

        }
    }


}



`;

const FooterWrapper = Styled.div`
    width: -webkit-fill-available;
    bottom:0;
    position:${(props) =>
      props.step === 3 && props.type === "course"
        ? "absolute"
        : props.step === 3 && props.type === "lecture"
        ? "absolute"
        : "static"};


    background: #181818;
    padding:24px 75px;

    h2 {
        font-weight: normal;
        font-size: 14px;
        margin:0;
    }

    a {
        font-weight: normal;
        font-size: 14px;

        &:first-child {
            margin-right:20px;
        }

    }


`;

const FooterCont = Styled.div`
width: 100%;
bottom: 0;
background: #181818;
display: flex;
padding: 24px 0;
position: relative;

.footer-cont{
    display: flex;
    padding: 0 75px;
    width: 100%;
    justify-content: space-between;
}
.copyright-text{
    position: relative;
    /* width: 214px; */
    height: 18px;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: rgba(255, 255, 255, 0.75);
}
.privacy-policy{
    display: flex;
    align-content: center;
    justify-content: space-between;
}
.privacy-policy ul{
    margin: 0px;
    padding: 0px;
    display: flex;
    justify-content: space-between;
    position: relative;
    align-self: center;
    width: 247px;
}
.privacy-policy li{
    position: relative;
    list-style: none;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-align: right;
    color: rgba(255, 255, 255, 0.75);
}


`;

const SingleStep = Styled.span`
display:inline-block;
margin-right:3px;
width:17px;
height:4px;
background: ${(props) => (props.active === true ? "#FF852C" : "#5A5A5A")};
border-radius:20px;


`;

const HoverBox = Styled.div`
padding:20px 20px;
a {
    display:inline-block;
    text-decoration:none;
    font-weight: 600;
    font-size: 17px;
    color: #FFFFFF;
   
}

 
`;

const Description = Styled.div`
   
  
   ul, ol {
       margin:0;
   }

p {

    margin:0;
    /* margin-top:${(props) => (props.nospace ? 0 : "18px")}; */

    font-family: 'Mulish', sans-serif;
    text-align:justify;
    font-size: 15px;
    line-height:1.8;
    color: rgba(255, 255, 255, 0.6);


    br {
        display:none;
    }
    &:last-child {
        margin:0;
    }


}

span {
    display:inline-block;
    color: #FF852C;
    font-family: 'Mulish', sans-serif;
    font-size: 15px;
    cursor:pointer;
    

}


.review_box {
       
    display:flex;
    align-items:center;

    img {
        width:100px;
        height:100px;;
        object-fit:cover;
        object-position:center;
        border-radius:100%;

    }

    .headings {
        margin-top:35px;
        h4 {
            margin:0;
            margin-left:21px;
            font-family: 'Mulish', sans-serif;
            font-size: 18px;
        }

        h6 {
            margin:0;
            margin-top:10px;
            margin-left:21px;
            font-family: 'Mulish', sans-serif;
            font-size: 14.6806px;
        }

        .rating {
            span {
                color:#F9A039;
            }
            
            
            .numbers {
                font-family: 'Mulish', sans-serif;
                color: rgba(255, 255, 255, 0.9);
                font-weight: 600;
                font-size: 14px;
            }

            margin-top:10px;
            margin-left:21px;
        }
    }

        

        


}
`;

const Heading = Styled.div`
display: flex;
align-self: ${(props) => (props.alignCenter === true ? "center" : "none")};;
h6 {
    margin:0;
    margin-top:${(props) => (props.nospace === true ? "0px" : "30px")};
    margin-bottom:${(props) => (props.nospace === true ? "20px" : "0px")};
    font-family: 'Mulish', sans-serif;
    font-weight: 600;
    font-size: 22px;
    letter-spacing: 0.8px;
}


`;
const SubsHeading = Styled.div`
h6 {
    margin:0;
    margin-top:10px;
    margin-bottom:0px;
    font-family: 'Mulish', sans-serif;
    font-weight: 600;
    font-size: 20px;
}
`;

const References = Styled.div`
    margin-top:46px;
    margin-bottom:${(props) => (props.gutter === true ? "60px" : "26px")};
    border-left: 2.5px solid rgba(255, 255, 255, 0.2);
    padding-left:13px;
    p {
        margin: 0;
        margin-bottom:15px;
        font-family: 'Mulish', sans-serif;
        font-size: 15px;
        span {
            color: rgba(255, 255, 255, 0.6); 
        }
        .seperator {
            display:inline-block;
            margin:0px 10px 0px 10px;
        }
    }

`;

const BindVideos = Styled.div`
display: auto;
.orange{
    color: #ff852c;
}
.white{
    color: rgba(255,255,255,0.6);
}
.multi_upload{
    display: flex;
    justify-content: space-between;
}

.scroll{
    max-height: 230px;
    overflow: hidden scroll !important;
}
.scroll::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px #181818;
    background-color: #181818;
    border-radius: 10px;
}

.scroll::-webkit-scrollbar
{
    width: 10px;
    border-radius: 10px;
    background-color: #323131;
}

.scroll::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    background-color: #FFF;
    background-image: -webkit-gradient(linear,
                                       40% 0%,
                                       75% 84%,
                                       from(#121111),
                                       to(#121111),
                                       color-stop(.6,#121111));
}

`;

const NavixWrapper = Styled.div`
margin-top:15px;
margin-bottom:15px;
padding-bottom:20px;
border-bottom: 0.56982px solid rgba(255, 255, 255, 0.2);

.box {
    display: flex;
    align-items: start;
        
    .courseLecImg{
        display: inline-block;
        width: 70px;
        height: 70px;
        text-decoration: none;
        border: 2px solid rgba(255,255,255,0.6);
        border-radius: 5px;
        object-fit: cover;
        object-position: center;

      }
      .child{
        display: flex;
        align-self: center;
      }

    img {
          object-fit:contain;
          object-position:center;
          width:100%;
          @media(max-width:959px) {
            width:160px;
           }
           @media (max-width: 599px) {
               width: 130px;
               height:100%;
           }
      }
    div {
        margin-top: 5px;

        h6 {
   
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
        }

        h5 {
            margin-top:8px;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;

            span {
                color: rgba(255, 255, 255, 1);
            }
        }
    }
  

}

`;

const TimelineHeader = Styled.div`
padding: 17px 18px 21px 18px;
.left_side {
display: flex;
align-items:center;
    img {
        
        height: 39px;
    }

    .btn {
        margin-left:17px;
        background: rgba(255, 133, 44, 0.15);
        border-radius: 5px;
        width:68px;
        height:39px;
    }

    h4 {
    font-weight: 600;
    font-size: 22px;
    margin-left:15px;

    @media(max-width:723px) {
    display:none;
    }
    }
}

.right_side {
    display: flex;
    align-items:center;
justify-content:end;
    span.svg {
          display: flex;
          justify-content:center;
          align-items:center;
          width:39px;
          height:39px;
          background-color:#262626;
          border-radius: 5px;
          margin-right:15px;
    }

    .btn {
        text-align:center
        display: inline-block;
         width:122px;
         height:39px;
         border-radius: 5px;
         font-weight: 600;
         font-size: 15px;
        
    }
}

`;

const TimelineWrapper = Styled.div`
    background: #181818;
    border-radius: 5px;
    height: 100%;

    .content {
        display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 209px;
        .timelineSettingwrapper {
   text-align:center;
   h2 {
      font-size: 49.107px;

      img {
          width: 45px;
          height: 45px;
      }
   }

   h4 {
    margin-top:15px;
    font-size: 16px;
    max-width:214px;
    font-style: normal;
    font-weight: normal;
    line-height:1.3;
    color: rgba(255, 255, 255, 0.4);
   }
        
  }


    }
    


`;

const LengthBar = Styled.div`
display:flex;
background: #363636;
align-items: center;
border-radius: 5.14817px;
padding:8px 7px;
.video_length {
     margin-right:16px;
     width: 60px;
}

.video_bar {
    display: flex;
  .bar {
       p {
           margin: 0;
           margin-left:-4px;
       }
       span {
        margin-right:8px;
        font-family: Mulish;
        font-style: normal; 
        font-weight: 300;
        font-size: 9px;
       
        text-align: center;
        color: #FFFFFF;
    }
  }
    
}



`;

const TimelineParts = Styled.div`
background: #212121;
border-radius: 5.14817px;
padding:8px;


`;

const Parts = Styled.div`

display: flex;
margin-bottom:6px ;
.icon {
        background: #151515;

        display:flex;
        justify-content:center;
        align-items:center;
        width:59px;
        height:49.2px;
        border-left:3px solid ${(props) => props.color} ;
        
    }

    .bar {
        margin-left:8px;
        width:100%;
        background: #151515;
        border-radius: 4.30622px;
        display:flex;
        position:relative;
        


        

    }




`;

const VoiceWrapper = Styled.div`
   
   h2 {

    font-style: normal;
    font-weight: normal;
    font-size: 18px !important;
    line-height: 23px;
   }

   .radio_group {
       .box {
           display: flex;
           align-items:center;
        h6 {
        font-style: normal;
        font-weight: 300;
        font-size: 13.7831px;
     
       }
       }
      
   }

   .single_item {
       margin:23px 0;
    background: #000000;
    border-radius: 5px;
    display:flex;
    justify-content:space-between;
    align-items:center;
    padding:0px 14px;

    .item {
    
        svg {
            margin-right:8px;
        }

        display: flex;
        align-items:center;

        h6 {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        }

    }
   }


`;

const BarLine = Styled.div`
position:absolute;

            width:${(props) => props.width}px;
            margin: 15px 15px 15px 0px;
            left:${(props) => props.left}px;
            height: 21px;
            display: flex;
            justify-content:space-between;
            background: ${(props) => props.color};
opacity: 0.6;
border-radius: 4px;
span {
    padding: 0px 5px 0px 5px;
    line-height:1.1;
    opacity: 1 !important;
    color:white !important;
}
        

`;

const Policy = Styled.div`
    width:60%;

    .text_heading{
        padding: 18px 0;
        font-size: 22px;
    }
    .content{
        color: rgba(255,255,255,0.7);
    }

`;

export {
  HeadWrapper,
  EarningDetail,
  HoverEffect,
  LayoutWrapper,
  FooterWrapper,
  FooterCont,
  SingleStep,
  HoverBox,
  Description,
  Heading,
  SubsHeading,
  References,
  BindVideos,
  NavixWrapper,
  TimelineHeader,
  TimelineWrapper,
  LengthBar,
  TimelineParts,
  Parts,
  TabPanels,
  TextFieldInput,
  VoiceWrapper,
  BarLine,
  Policy,
};
