import { createSvgIcon } from "@mui/material/utils";

const MyContent = createSvgIcon(
  <svg
    viewBox="0 0 26 21"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.3497 2.41826H7.4857C7.0872 2.41826 6.76415 2.09521 6.76415 1.6967V1.33566C6.76415 0.929359 6.43476 0.599976 6.02847 0.599976H2.16008C1.34749 0.599976 0.688721 1.25869 0.688721 2.07134V14.4519L2.57661 5.26707C2.64559 4.93155 2.94087 4.69077 3.28341 4.69077H21.8211V3.88962C21.8211 3.07698 21.1623 2.41826 20.3497 2.41826Z"
      fill="currentColor"
    />
    <path
      d="M24.1572 6.13388H3.87174L1.03252 19.9467C0.939389 20.3999 1.28553 20.8245 1.74823 20.8245H22.8575C23.6075 20.8245 24.2375 20.2604 24.3199 19.5149L25.6196 7.76706C25.716 6.89567 25.0338 6.13388 24.1572 6.13388Z"
      fill="currentColor"
    />
  </svg>,
  "MyContent"
);

export default MyContent;
