import { createSvgIcon } from "@mui/material/utils";

const Sections = createSvgIcon(
  <svg
    width="24"
    height="20"
    viewBox="0 0 24 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.1768 10.5706C13.9818 10.3756 13.8597 10.1038 13.8597 9.80433C13.8597 9.20547 14.3443 8.72087 14.9431 8.72087H19.4077L17.9362 7.24934C17.5127 6.82581 17.5127 6.14027 17.9362 5.71674C18.3597 5.29321 19.0453 5.29321 19.4688 5.71674L22.7921 9.04002C22.9181 9.16608 23.0127 9.32367 23.062 9.49507C23.062 9.49507 23.062 9.49507 23.062 9.49706C23.125 9.70982 23.1191 9.9403 23.0501 10.1491C22.9969 10.3067 22.9063 10.4525 22.7901 10.5707L19.4649 13.892C19.0413 14.3155 18.3558 14.3155 17.9323 13.892C17.5087 13.4684 17.5087 12.7829 17.9323 12.3594L19.4038 10.8878H14.9411C14.6437 10.8878 14.3718 10.7656 14.1768 10.5706Z"
      fill="currentColor"
    />
    <path
      d="M8.925 10.5706C9.12 10.3756 9.24216 10.1038 9.24216 9.80433C9.24216 9.20547 8.75756 8.72087 8.1587 8.72087H3.69605L5.16758 7.24934C5.59111 6.82581 5.59111 6.14027 5.16758 5.71674C4.74405 5.29321 4.05851 5.29321 3.63498 5.71674L0.315657 9.03803L0.313687 9.04002C0.187612 9.16608 0.0930557 9.32367 0.0438076 9.49507C0.0438076 9.49507 0.0438076 9.49507 0.0438076 9.49706C-0.01923 9.70982 -0.0133202 9.9403 0.0556272 10.1491C0.108815 10.3067 0.199432 10.4525 0.315657 10.5707L3.63695 13.892C4.06048 14.3155 4.74602 14.3155 5.16955 13.892C5.59308 13.4684 5.59308 12.7829 5.16955 12.3594L3.69605 10.8878H8.16067C8.45813 10.8878 8.72998 10.7656 8.925 10.5706Z"
      fill="currentColor"
    />
    <path
      d="M12.9299 18.1509V1.37895C12.9299 0.616586 12.3133 0 11.5509 0C10.7885 0 10.172 0.616586 10.172 1.37895V18.1489C10.172 18.9113 10.7885 19.5279 11.5509 19.5279C12.3133 19.5279 12.9299 18.9113 12.9299 18.1509Z"
      fill="currentColor"
    />
  </svg>,
  "Sections"
);

export default Sections;
