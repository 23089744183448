import { Link as RouterLink } from "react-router-dom";
import { Box, ListItemText, MenuItem, Typography } from "@mui/material";
import { DropDown } from "./style";

const ProfileDropDown = ({ handleLogout, handleClose }) => (
  <DropDown>
    <Box className="box_body">
      <MenuItem
        component={RouterLink}
        to="/dashboard/profile"
        onClick={handleClose}
      >
        <ListItemText
          primary={
            <Typography color="textPrimary" variant="subtitle2">
              My Profile
            </Typography>
          }
        />
      </MenuItem>

      <MenuItem onClick={handleLogout}>
        <ListItemText
          primary={
            <Typography color="textPrimary" variant="subtitle2">
              Logout
            </Typography>
          }
        />
      </MenuItem>
    </Box>
  </DropDown>
);

export default ProfileDropDown;
