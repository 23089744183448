import { Rnd } from "react-rnd";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { highLightRegion } from "../pages/videos/Annotations";
import { ExpandableBox } from "../components/video/trimStyles";
import Description from "../components/video/DescriptionBox";

const READ_MODE = "read";
const WRITE_MODE = "write";
const SPEECH_BUBBLE_PATH = "/static/images/Speechbubble.png";

const MovableAnnotationBox = ({
  position,
  description,
  mode,
  showExpandButton,
  onClickExpandButton,
  showDescriptionBoxAlways,
  onResize,
  onDragStop,
  onSave,
  onClose,
}) => (
  <ExpandableBox
    style={{
      ...highLightRegion,
      ...{ top: "unset", left: "unset" },
    }}
  >
    <Rnd
      className="resizable"
      default={position}
      lockAspectRatio
      bounds="parent"
      onResize={onResize}
      onDragStop={onDragStop}
      enableResizing={mode !== READ_MODE}
      disableDragging={mode === READ_MODE}
    >
      <div className="resizers">
        <div className="resizer top-left" />
        <div className="resizer top-right" />
        <div className="resizer bottom-left" />
        <div className="resizer bottom-right" />
      </div>
      {showExpandButton == true && (
        <img
          onClick={onClickExpandButton}
          className="expandable-img expandable-img-src"
          src={SPEECH_BUBBLE_PATH}
          alt=""
        />
      )}

      {(showDescriptionBoxAlways || showExpandButton == false) && (
        <Box className="expandable-img expandable-box">
          <Description
            mode={mode}
            description={description}
            onSave={onSave}
            onClose={onClose}
          />
        </Box>
      )}
    </Rnd>
  </ExpandableBox>
);

MovableAnnotationBox.defaultProps = {
  showExpandButton: false,
};
MovableAnnotationBox.propTypes = {
  /**
   * Text displayed in the annotation box
   */
  description: PropTypes.string.isRequired,
  /**
   *
   */
  position: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
    width: PropTypes.string,
    height: PropTypes.string,
  }),
  /**
   * is the annotation box editable or not
   */
  mode: PropTypes.oneOf([READ_MODE, WRITE_MODE]).isRequired,

  /**
   * Callback invoked when resizing the box
   */
  onResize: PropTypes.func,

  /**
   * Callback invoked when the box drag is stopped
   */
  onDragStop: PropTypes.func,

  /**
   * Callback invoked when clicked on "Save" button when editing the annotation
   */
  onSave: PropTypes.func,

  /**
   * Callback invoked when clicked on "Close" button when editing the annotation
   */
  onClose: PropTypes.func,

  /**
   * Boolean to show description box always or it depends on expandButton
   */
  showDescriptionBoxAlways: PropTypes.bool,

  /**
   * Boolean to show expand button or not
   */
  showExpandButton: PropTypes.bool,

  /**
   * Callback invoked on click of expand button. Only valid if showExpandButton is true
   */
  onClickExpandButton: PropTypes.func,
};

export default MovableAnnotationBox;
