import PropTypes from "prop-types";
import { Box, Grid, Typography } from "@mui/material";
import { HistoryList } from "./trimStyles";
import Scrollbar from "../Scrollbar";
import Reset from "../../icons/ResetIcon";

function HistoryRecords({ removeFormList, isSegmentProcessed, historys }) {
  return (
    <Scrollbar style={{ height: "auto" }}>
      <Grid style={{ height: 560 }}>
        {historys &&
          historys.map((history, index) => (
            <HistoryList key={index}>
              <Box className="history-list">
                <Typography color="textPrimary" variant="h6">
                  {history && history.val && (
                    <>
                      Actual length : <span>{history && history.val}</span>
                    </>
                  )}
                  {history?.raw?.index &&
                    `Duration : ${history?.start} - ${history?.end}`}
                  {history?.raw?.type === "REGION" &&
                    `Start at: ${history?.start}, 
                        Pause Duration :  ${
                          history?.raw?.pause_duration
                            ? history.raw.pause_duration
                            : 3
                        } sec `}
                  {history?.raw?.type === "segment" &&
                    `Duration : ${history?.start} - ${history?.end}`}
                </Typography>
              </Box>
              {!isSegmentProcessed && (
                <Box display="flex" className="history-list-btns">
                  <div className="edit">
                    <Reset
                      onClick={() =>
                        removeFormList(
                          index,
                          history?.id ?? history.raw?.id,
                          history
                        )
                      }
                    />
                  </div>
                </Box>
              )}
            </HistoryList>
          ))}
      </Grid>
    </Scrollbar>
  );
}

HistoryRecords.propTypes = {
  /**
   * Histories array
   */
  historys: PropTypes.array,
  /**
   * Callback invoked on click of remove button
   */
  removeFormList: PropTypes.func,
};

export default HistoryRecords;
