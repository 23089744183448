import { EtChiliTheme } from ".";
import base from "./base";
import { darkColors } from "./colors";

const darkTheme: EtChiliTheme = {
  ...base,
  isDark: true,
  colors: darkColors,
};

export default darkTheme;
