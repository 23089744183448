import { createSvgIcon } from "@mui/material/utils";

const DashboardIcon = createSvgIcon(
  <svg
    viewBox="0 0 27 28"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.2172 15.536H12.7485V5.70228C12.7485 5.2421 12.3756 4.86919 11.9158 4.86919C5.77241 4.86919 0.774414 9.86719 0.774414 16.0105C0.774414 22.1539 5.77241 27.1519 11.9158 27.1519C14.8241 27.1519 17.5761 26.038 19.665 24.015C21.7487 21.9969 22.9508 19.2919 23.0496 16.3973C23.0573 16.1716 22.973 15.9525 22.8162 15.7903C22.6592 15.6277 22.4429 15.536 22.2172 15.536Z"
      fill="currentColor"
    />
    <path
      d="M15.4931 0.885742C15.0332 0.885742 14.6604 1.25857 14.6604 1.71875V12.3855C14.6604 12.8456 15.0332 13.2185 15.4931 13.2185H25.7947C26.2435 13.2185 26.6117 12.8627 26.6271 12.4141C26.6321 12.2675 26.6344 12.1446 26.6344 12.0271C26.6344 5.88374 21.6364 0.885742 15.4931 0.885742Z"
      fill="currentColor"
    />
  </svg>,
  "DashboardIcon"
);

export default DashboardIcon;
