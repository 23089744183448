import { CSSProperties, useContext, useEffect, useRef, useState } from "react";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { apiService } from "@8chili/services";
import TimeSlider from "./TimeSlider";
import CutTimeField from "./CutTimeField";
import "./ResizeableBox.css";
import "./ChildRepArrow.css";
import MovableAnnotationBox from "../../simple/MovableAnnotationBox";
import Historyicon from "../../icons/History.png";
import VideoContext from "../../contexts/video";
import {
  History,
  HistoryItems,
  HistorySeciton,
  Timer,
  UndoBtn,
} from "../../components/video/trimStyles";
import HistoryRecord from "../../components/video/HistoryRecords";
import Header from "../../components/video/Header";
import DescriptionBox from "../../components/video/DescriptionBox";
import PreviewModal from "../../components/video/components/PreviewModal";
import ApplyButton from "../../components/video/ApplyButton";
import AnnotationSection from "../../components/video/AnnotationSection";
import { useToast } from "@8chili/uikit";

export const ENDTIME_SECS = 3;

type MyTypingType = {
  position: CSSProperties["position"];
  top: CSSProperties["top"];
  left: CSSProperties["left"];
  display: CSSProperties["display"];
  zIndex: CSSProperties["zIndex"];
};

export const myTyping: MyTypingType = {
  position: "absolute",
  top: "125px",
  left: "100px",
  display: "block",
  zIndex: "2",
};
export const highLightRegion = {
  width: "100%",
  height: "100%",
  zIndex: "2",
};
export const expandableImg = {
  position: "absolute",
  top: "40%",
  left: " 100%",
};
export const expandableImgBox = {
  position: "absolute",
  top: " 0%",
  left: " 100%",
  marginLeft: "30px",
};

const Annotations = () => {
  const { videoDispatcher, trim, annote } = useContext(VideoContext);
  const videoPlayerRef = useRef<any>();
  const [timeRange, settimeRange] = useState(3);
  const [startTime, setStartTime] = useState(0);
  const [_, setMin] = useState(0);
  const [__, setMax] = useState(0);
  const [show, setShow] = useState(false);
  const [showRegion, setShowRegion] = useState(false);
  const [description, setDescription] = useState("");
  const [annotationRegionDescription, ___] = useState("");
  const [annotationType, setAnnotationType] = useState("");
  const [mode, setMode] = useState("read");
  const [expandable, setexpandable] = useState(true);
  const [position, setPosition] = useState({
    x: 0,
    y: 0,
    width: "135px",
    height: "135px",
  });
  const [____, setActions] = useState(false);
  const { toastSuccess } = useToast();

  const onResize = (event, direction, ref) => {
    const { height, width } = ref.style;
    setPosition((prevPosition) => ({
      ...prevPosition,
      width,
      height,
    }));
  };

  const onDragStop = (e, d) => {
    const { x, y } = d;
    setPosition((prevPosition) => ({
      ...prevPosition,
      x,
      y,
    }));
  };
  useEffect(() => {
    setMin(trim.startTime);
    setMax(trim.endTime);
    setStartTime(trim.startTime);
  }, [trim.startTime, trim.endTime]);

  const resetHandler = () => {
    setStartTime(trim.startTime);
    setMin(trim.startTime);
    setMax(trim.endTime);
  };

  const handleJumpVideoTime = () => {
    if (videoPlayerRef.current) {
      videoPlayerRef.current.currentTime = startTime;
      toastSuccess("video time set for annotation");
    }
  };

  const getApiBody = (type, _description) => {
    const common = {
      name: _description,
      type: annotationType.toLowerCase() === "speech" ? "text" : "region",
      play_time: startTime,
      pause_duration: timeRange,
    };
    if (type === "speech") {
      return {
        ...common,
        x: 100 / 1094,
        y: 125 / 615,
        height: undefined,
        width: undefined,
      };
    }
    return {
      ...common,
      x: position.x / videoPlayerRef.current.offsetWidth,
      y: position.y / videoPlayerRef.current.offsetHeight,
      width:
        Number(position.width.replace("px", "")) /
        videoPlayerRef.current.offsetWidth,
      height:
        Number(position.height.replace("px", "")) /
        videoPlayerRef.current.offsetHeight,
    };
  };

  const handleAnnote = async (_description) => {
    const recordingId = localStorage.getItem("recording_id");
    const apiBody = getApiBody(annotationType.toLowerCase(), _description);

    await apiService
      .postData(`/api/video/${recordingId}/annotation/add`, apiBody)
      .then((res) => {
        if (res) {
          setActions(true);
          setStartTime(trim.startTime);
          setMin(trim.startTime);
          setShow(false);
          setShowRegion(false);
          setMode("read");
          setDescription("");
          setAnnotationType("");
          settimeRange(3);
          videoDispatcher({
            type: "UPDATE_ANNOTE_HISTORY",
            payload: [
              {
                ...apiBody,
                width: apiBody?.width,
                height: apiBody?.height,
                startTime,
                description: _description,
                type: annotationType,
                id:
                  res &&
                  res.data &&
                  res.data.annotations &&
                  res.data.annotations[res.data.annotations.length - 1]?.id,
              },
            ],
          });
        }
      })
      .catch((err) => {
        if (err) {
          setActions(false);
        }
      });
  };

  const startAnnotate = async () => {
    const recordingId = localStorage.getItem("recording_id");

    await apiService
      .getData(`/api/video/${recordingId}/annotations/apply`)
      .then((res) => {
        if (res) {
          setMin(trim.startTime);
          setShow(false);
          setShowRegion(false);
          setMode("read");
          setDescription("");
          setAnnotationType("");
          toastSuccess("Applied");
        }
      });
  };

  const getHistoryTime = (secs) => {
    if (secs) {
      const secNum = parseInt(secs.toString(), 10);
      const hours = Math.floor(secNum / 3600);
      const minutes = Math.floor(secNum / 60) % 60;
      const seconds = secNum % 60;

      return [hours, minutes, seconds]
        .map((val) => (val < 10 ? `0${val}` : val))
        .filter((val, index) => val !== "00" || index > 0)
        .join(":")
        .replace(/^0/, "");
    }
  };

  const removeFormList = async (index, annotation_id, history) => {
    resetHandler();
    setShow(false);
    setShowRegion(false);
    const annote_id = history && history.raw && history.raw.id;
    const recordingId = localStorage.getItem("recording_id");

    await apiService
      .getData(`/api/video/${recordingId}/annotation/${annote_id}/delete`)
      .then((res) => {
        if (res) {
          videoDispatcher({
            type: "REMOVE_ANNOTE_HISTORY",
            payload: index,
          });
        }
      })
      .catch((err) => {
        if (err) {
          console.error(err);
        }
      });
  };

  const handleClose = () => {
    setAnnotationType("");
    setShow(false);
    setShowRegion(false);
    setMin(trim.startTime);
    setStartTime(trim.startTime);
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={5} md={4} lg={3} xl={3}>
          <div style={{ height: "100%" }}>
            <Header
              title="Annotations"
              tooltip="Annotate Video"
              undoBtn={undefined}
              handleDeleteAllSegment={undefined}
              setopenConfirmBox={undefined}
              openConfirmBox={undefined}
              isStartVoice={undefined}
            />
            <Timer>
              <Box pb={2} sx={{ display: "flex" }}>
                <Box mr={2}>
                  <Typography color="textPrimary" variant="h2">
                    Jump to
                  </Typography>
                  <Box style={{ display: "flex" }}>
                    <CutTimeField
                      timeInSec={startTime}
                      placeholder="hh:mm:ss"
                      onChange={setStartTime}
                      min={trim.startTime}
                      max={trim.endTime}
                    />
                    <UndoBtn annote>
                      <Button className="jumpBtn" onClick={handleJumpVideoTime}>
                        Jump
                      </Button>
                    </UndoBtn>
                  </Box>
                </Box>
              </Box>
              <AnnotationSection
                onChange={({ target }) => {
                  setAnnotationType(target.value);
                  setMode("edit");
                  setShow(target.value === "SPEECH");
                  setShowRegion(target.value !== "SPEECH");
                  setDescription("");
                  setStartTime(videoPlayerRef.current?.currentTime);
                  videoPlayerRef.current.pause();
                }}
                type={annotationType}
                on_click_reset={handleClose}
              />
              {showRegion ? (
                <div className="range-slider">
                  <TimeSlider value={timeRange} setvalue={settimeRange} />
                </div>
              ) : null}

              <Divider />
              <HistorySeciton>
                <History>
                  <Typography color="textPrimary" variant="h6">
                    History
                  </Typography>
                  <ApplyButton
                    isEdit
                    buttonTitle="Confirm All"
                    on_click_btn={startAnnotate}
                    current={""}
                    loading={false}
                    addSeg={false}
                    isAudio={false}
                    shouldDisable={false}
                  />
                </History>
                {!annote.history.length ? (
                  <HistoryItems>
                    <>
                      <img src={Historyicon} height={47} width={41} alt="" />
                      <Typography color="textPrimary" variant="h3">
                        History of all your Annotate
                        <br /> appear here
                      </Typography>
                    </>
                  </HistoryItems>
                ) : (
                  <HistoryRecord
                    historys={annote.history.map((hs) => ({
                      start: getHistoryTime(hs.startTime),
                      end: getHistoryTime(hs.endTime),
                      raw: hs,
                    }))}
                    removeFormList={removeFormList}
                    isSegmentProcessed={undefined}
                  />
                )}
                {/* image ad  */}
              </HistorySeciton>
            </Timer>
          </div>
        </Grid>
        <Grid
          item
          style={{
            height: "100%",
            width: "100%",
            padding: "25px 18px",
            marginTop: 12,
            position: "relative",
          }}
          xs={12}
          sm={7}
          md={8}
          lg={9}
          xl={9}
        >
          <div style={myTyping}>
            {show ? (
              <DescriptionBox
                mode={mode}
                description={description}
                onSave={(_desc) => handleAnnote(_desc)}
                onClose={handleClose}
              />
            ) : null}
          </div>
          {showRegion ? (
            <MovableAnnotationBox
              mode={mode}
              position={position}
              onResize={onResize}
              onDragStop={onDragStop}
              showExpandButton={expandable}
              showDescriptionBoxAlways={false}
              onClickExpandButton={() => setexpandable(false)}
              description={annotationRegionDescription}
              onSave={(_desc) => handleAnnote(_desc)}
              onClose={handleClose}
            />
          ) : null}
          <PreviewModal
            videoPlayer={videoPlayerRef}
            colors_correction={undefined}
            setActive={undefined}
            partStart={undefined}
            partEnd={undefined}
            onVideoStart={undefined}
            setPlay={undefined}
            record={undefined}
            play={undefined}
            setRecord={undefined}
            setsegPauseTime={undefined}
            segPauseTime={undefined}
            setsegPlayTime={undefined}
            segPauseDuration={undefined}
            setsegPauseDuration={undefined}
            currSegmentPauseEvents={undefined}
            setcurrSegmentPauseEvents={undefined}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Annotations;
