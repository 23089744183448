import PropTypes from "prop-types";
import Radio from "@mui/material/Radio";
import { Box, Typography } from "@mui/material";
import { Annoate } from "./trimStyles";
import Reset from "../../icons/Reset.png";

const AnnotationSection = ({ on_click_reset, onChange, type = "SPEECH" }) => (
  <>
    <Annoate>
      <Typography mt={1} color="textPrimary" variant="h6">
        Annotation type
      </Typography>
      <div className="annoation-types">
        <Box className="annoation-types-btns">
          <Radio
            checked={type === "REGION"}
            onChange={onChange}
            value="REGION"
            name="annotate"
            inputProps={{ "aria-label": "B" }}
          />

          <Typography color="textPrimary" variant="h6">
            Highlight a region
          </Typography>
        </Box>
        <img
          style={{ cursor: "pointer" }}
          onClick={on_click_reset}
          src={Reset}
          height={39}
          width={39}
          alt=""
          className="resetBtn"
        />
      </div>
    </Annoate>
  </>
);

AnnotationSection.propTypes = {
  /**
   * Button Title
   */
  buttonTitle: PropTypes.string.isRequired,

  /**
   * Annotate Type
   */
  type: PropTypes.string,

  /**
   * Callback invoked when clicked on reset button
   */
  on_click_reset: PropTypes.func,
};

export default AnnotationSection;
