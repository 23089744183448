import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

export const AlertError: React.FC<React.PropsWithChildren<SvgProps>> = (
  props
) => (
  <Svg viewBox="0 0 24 24" {...props}>
    <ellipse cx="11.6553" cy="12" rx="11.6553" ry="12" fill="#F44336" />
    <path
      d="M15.0669 6.80916C15.5171 6.34568 16.2469 6.34568 16.6971 6.80916C17.1473 7.27263 17.1473 8.02409 16.6971 8.48756L13.3195 11.965C13.3008 11.9843 13.3008 12.0157 13.3195 12.035L16.6971 15.5125C17.1473 15.976 17.1473 16.7274 16.6971 17.1909C16.2469 17.6543 15.5171 17.6543 15.0669 17.1909L11.6893 13.7134C11.6706 13.6941 11.6402 13.6941 11.6214 13.7134L8.24382 17.1909C7.79366 17.6543 7.06379 17.6543 6.61362 17.1909C6.16346 16.7274 6.16346 15.976 6.61362 15.5125L9.99123 12.035C10.01 12.0157 10.01 11.9843 9.99123 11.965L6.61362 8.48756C6.16346 8.02409 6.16346 7.27263 6.61362 6.80916C7.06379 6.34568 7.79366 6.34568 8.24382 6.80916L11.6214 10.2866C11.6402 10.306 11.6706 10.306 11.6893 10.2866L15.0669 6.80916Z"
      fill="white"
    />
  </Svg>
);

export default AlertError;
