import { createSvgIcon } from "@mui/material/utils";

const ResetIcon = createSvgIcon(
  <svg
    width="15"
    height="13"
    viewBox="0 0 15 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.459851 2.94252C0.574885 2.81266 0.771565 2.77921 0.968419 2.86139L2.61792 3.55016C3.70894 1.80966 5.68229 0.644775 7.93067 0.644775C11.357 0.644775 14.1445 3.34987 14.1445 6.6749C14.1445 9.99992 11.357 12.705 7.93067 12.705C7.45172 12.705 7.06347 12.3283 7.06347 11.8635C7.06347 11.3987 7.45172 11.0219 7.93067 11.0219C10.4007 11.0219 12.4101 9.07181 12.4101 6.6749C12.4101 4.27798 10.4007 2.32789 7.93067 2.32789C6.39664 2.32789 5.04043 3.08024 4.23277 4.2245L5.80547 4.88121C5.97393 4.95156 6.07925 5.07598 6.09451 5.22258C6.10973 5.36914 6.03207 5.51149 5.88148 5.61302L1.8272 8.34719C1.73489 8.40943 1.63434 8.44237 1.53643 8.44237C1.31842 8.44237 1.14702 8.28374 1.11008 8.04772L0.368492 3.31095C0.345728 3.16557 0.378161 3.03475 0.459851 2.94252Z"
      fill="#999999"
    />
  </svg>,

  "ResetIcon"
);

export default ResetIcon;
