import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import useAuth from "../../hooks/useAuth";

const GuestGuard = ({ children }) => {
  const { isAuthenticated, user } = useAuth();

  if (user?.status === "InComplete" && user?.msg === "EmailVerificationPending") {
    return <Navigate to="/verify-code" />;
  }

  if (user?.status === "InComplete" && user?.msg === "PendingOrgDetails") {
    return <Navigate to="/additional-info" />;
  }

  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  return <>{children}</>;
};

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default GuestGuard;
