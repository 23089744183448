import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import { experimentalStyled } from "@mui/material";
import { AppBar, Box, IconButton, Toolbar } from "@mui/material";
import UserGreeting from "./UserGreeting";
import AccountPopover from "./AccountPopover";
import MenuIcon from "../../icons/Menu";
import useAuth from "../../hooks/useAuth";

const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === "light" && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: "none",
    color: theme.palette.text.primary,
  }),
  ...(theme.palette.mode === "dark" && {
    backgroundColor: theme.palette.background.default,

    boxShadow: "none",
    width: "100%",
    [theme.breakpoints.up("lg")]: {
      width: "95%",
    },
    "@media (min-width: 1280px) and (max-width: 1420px)": {
      width: "94%",
    },
    "@media (min-width: 2201px) ": {
      width: "96%",
    },
  }),
  zIndex: theme.zIndex.drawer + 100,
}));

const StyledToolbar = experimentalStyled(Toolbar)(() => ({
  "@media (max-width: 1279px)": {
    paddingLeft: "5px",
  },
  "@media (min-width: 1280px) and (max-width: 1740px)": {
    paddingLeft: "24px",
  },
  "@media (min-width: 1741px) ": {
    paddingLeft: "10px",
  },
}));

const DashboardNavbar = ({ onSidebarMobileOpen, title, ...other }) => {
  const { user } = useAuth();

  return (
    <DashboardNavbarRoot {...other}>
      <StyledToolbar
        sx={{
          minHeight: 64,
        }}
      >
        <IconButton
          color="inherit"
          onClick={onSidebarMobileOpen}
          sx={{
            display: {
              lg: "none",
            },
          }}
        >
          <MenuIcon fontSize="medium" />
        </IconButton>
        <RouterLink to="/" style={{ textDecoration: "none" }}>
          <UserGreeting name={user && `${user.first_name} ${user.last_name}`} title={title} />
        </RouterLink>
        <Box
          sx={{
            flexGrow: 1,
            ml: 2,
          }}
        />
        <Box sx={{ ml: 2 }}>
          <AccountPopover />
        </Box>
      </StyledToolbar>
    </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
};

export default DashboardNavbar;
