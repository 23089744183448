import { apiService } from "@8chili/services";

export function lecturesAll(limit, offset, title = undefined) {
  const requestURL = `/api/v2.0/lecture/all`;
  const requestObj: any = { limit, offset };

  if (title) {
    requestObj.q_title = title;
  }

  return apiService.postData(requestURL, requestObj);
}

export function getAllLectures(limit, state, archived, offset) {
  const requestURL = `/api/v2.0/lecture/all`;
  const requestObj: any = {};
  requestObj.limit = limit;
  if (state) {
    requestObj.state = state;
  }
  if (archived === true) {
    requestObj.archived = archived;
  }
  if (offset === 0 || offset > 0) {
    requestObj.offset = offset;
  }

  return apiService.postData(requestURL, requestObj);
}

export function filterLecture(q_title = undefined) {
  const requestURL = `/api/v2.0/lecture/all`;
  const requestObj: any = {};

  if (q_title) {
    requestObj.q_title = q_title;
  }

  return apiService.postData(requestURL, requestObj);
}

export function allRecordings(limit, offset, title = undefined) {
  const requestURL = `/api/video/recording/all`;
  const requestObj: any = { limit, offset };

  if (title) {
    requestObj.q_title = title;
  }

  return apiService.postData(requestURL, requestObj);
}
export function filterRecordings(q_title) {
  const requestURL = `/api/video/recording/all`;
  const requestObj = { q_title };
  return apiService.postData(requestURL, requestObj, {});
}

export function unassignedRecordings(pagingState) {
  const requestURL = `/api/video/unassigned_recordings?page=${pagingState}`;
  return apiService.getData(requestURL);
}

export function getLectureDetail(lectureId) {
  const requestURL = `/api/v2.0/lecture/detail/${lectureId}`;
  return apiService.getData(requestURL);
}

export function deleteLecture(lecture_id) {
  const requestURL = `/api/v2.0/lecture/delete/${lecture_id}`;
  return apiService.getData(requestURL);
}

export function archiveLecture(lecture_id) {
  const requestURL = `/api/v2.0/lecture/archive/${lecture_id}`;
  return apiService.getData(requestURL);
}

export function undoArchiveLecture(lecture_id) {
  const requestURL = `/api/v2.0/lecture/undo_archive/${lecture_id}`;
  return apiService.getData(requestURL);
}

export function attachRecording(lecture_id, recording_id, values) {
  const requestURL = `/api/v2.0/lecture/${lecture_id}/attach_recording/${recording_id}`;
  const requestObj: any = {};
  if (values) {
    requestObj.patient_details = {
      case_diagnosis: values.case,
      yob: values.yob,
      mob: values.mob,
      patient_history: values.patientHistory,
    };
    if (values.gender) {
      requestObj.patient_details = {
        gender: values.gender,
      };
    }
    requestObj.name = values.procedureName;
    requestObj.description = values.procedureDesc;
    requestObj.tools_used = values.tools;
    requestObj.surgeon_names = values.surgeons;
  }
  return apiService.postData(requestURL, requestObj);
}

export function attachRecordings(lecture_id, recordings) {
  const requestURL = `/api/v2.0/lecture/${lecture_id}/attach_recording`;
  const requestObj = {
    recording_seq: recordings.map((rec) => rec.id),
  };

  return apiService.postData(requestURL, requestObj);
}

export function detachRecording(lecture_id, recording_id) {
  const requestURL = `/api/v2.0/lecture/${lecture_id}/detach_recording/${recording_id}`;

  return apiService.getData(requestURL);
}

export function uploadNewRecording(lecture_id, recording_id, values) {
  const requestURL = `/api/v2.0/lecture/${lecture_id}/update_recording/${recording_id}`;
  const requestObj: any = {};
  if (values) {
    requestObj.patient_details = {
      case_diagnosis: values.case,
      yob: values.yob,
      mob: values.mob,
      patient_history: values.patientHistory,
      // gender: values.gender,
    };
    if (values.gender) {
      requestObj.patient_details = {
        gender: values.gender,
      };
    }
    requestObj.name = values.procedureName;
    requestObj.description = values.procedureDesc;
    requestObj.tools_used = values.tools;
    requestObj.surgeon_names = values.surgeons;
  }

  return apiService.postData(requestURL, requestObj);
}

export function deleteSegment(recording_id, segment_id) {
  const requestURL = `/api/video/${recording_id}/segment/${segment_id}/delete`;

  return apiService.getData(requestURL);
}

export function deleteAllSegment(recording_id) {
  const requestURL = `/api/video/${recording_id}/segment/delete_all`;

  return apiService.getData(requestURL);
}

export function deleteAudio(recording_id, segment_id) {
  const requestURL = `/api/video/${recording_id}/segment/${segment_id}/delete_audio`;

  return apiService.getData(requestURL);
}

export function updateRecording(recording_id, values) {
  const requestURL = `/api/video/${recording_id}/update_recording`;
  const requestObj: any = {};

  requestObj.name = values.procedureName;
  requestObj.description = values.procedureDesc;
  requestObj.tools_used = values.tools;
  requestObj.surgeon_names = values.surgeons;
  if (values.gender) {
    requestObj.patient_details = {
      gender: values.gender,
      case_diagnosis: values.case,
      patient_history: values.patientHistory,
      yob: values.yob,
      mob: values.mob,
    };
  } else {
    requestObj.patient_details = {
      case_diagnosis: values.case,
      patient_history: values.patientHistory,
      yob: values.yob,
      mob: values.mob,
    };
  }

  return apiService.postData(requestURL, requestObj, {});
}

export function createRecording(values, videoType) {
  const requestURL = `/api/video/create_recording`;
  const formData = new FormData();
  formData.append("name", values.procedureName);
  formData.append("description", values.procedureDesc);
  if (values.tools && values.tools.length > 0) {
    values.tools.map((tool) => formData.append("tools_used", tool));
  }
  if (values.surgeons && values.surgeons.length > 0) {
    values.surgeons.map((surgeon) => formData.append("surgeon_names", surgeon));
  }
  // let requestObj = {};
  if (values.gender) {
    const requestObj = {
      gender: values.gender,
      case_diagnosis: values.case,
      patient_history: values.patientHistory,
      yob: values.yob,
      mob: values.mob,
    };
    formData.append("patient_details", JSON.stringify(requestObj));
  } else {
    const requestObj = {
      case_diagnosis: values.case,
      patient_history: values.patientHistory,
      yob: values.yob,
      mob: values.mob,
    };
    formData.append("patient_details", JSON.stringify(requestObj));
  }
  formData.append("video_type", videoType);

  return apiService.postForm(requestURL, formData);
}

export function reuploadRecordingVideo(recordingId) {
  const requestURL = `/api/video/recording/${recordingId}/reupload`;

  return apiService.getData(requestURL);
}

export function allLecQnA(lecture_id) {
  const requestURL = `/api/v1.0/lecture_qna/${lecture_id}/all`;

  return apiService.getData(requestURL);
}

export function applyannotation(recordingId) {
  const requestURL = `/api/video/${recordingId}/annotations/apply`;
  return apiService.getData(requestURL);
}

// *********** Podcast Apis *************

export function getPodcast(podcastId, lectureId) {
  const requestURL = `/api/v1.0/podcasts/${podcastId}/details/lec/${lectureId}`;
  return apiService.getData(requestURL);
}

export function allModelAssets() {
  const requestURL = `/api/v1.0/podcasts/assets/all?category_id=1&sub_category_id=1`;
  return apiService.getData(requestURL);
}

export function getAllModelCategories() {
  const requestURL = `/api/v1.0/podcasts/assets/categories`;
  return apiService.getData(requestURL);
}

export function addPodcast(lecture_id, values) {
  const requestURL = `/api/v1.0/podcasts/add/lecture/${lecture_id}`;
  const formData = new FormData();

  formData.append("name", values.name);
  if (values.podcastImage) {
    formData.append("thumbnail", values.podcastImage);
  }
  if (values.models && values.models.length > 0) {
    values.models.map((asset) => formData.append("asset_bundles", asset));
  }
  if (values.attachments && values.attachments.length > 0) {
    values.attachments.map((attachment) =>
      formData.append("attachments", attachment)
    );
  }

  if (values.start_ts !== "") formData.append("start_ts", values.start_ts);
  if (values.region !== "") formData.append("region", values.region);

  return apiService.postData(requestURL, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function updatePodcast(podcastID, values, lectureId) {
  const requestURL = `/api/v1.0/podcasts/${podcastID}/update/lec/${lectureId}`;
  const formData = new FormData();

  formData.append("name", values.name);
  if (values.podcastImage) {
    formData.append("thumbnail", values.podcastImage);
  }
  if (values.models && values.models.length > 0) {
    values.models.map((asset) => formData.append("asset_bundles", asset));
  }
  if (values.attachments && values.attachments.length > 0) {
    values.attachments.map((attachment) =>
      formData.append("attachments", attachment)
    );
  }

  if (values.start_ts !== "") formData.append("start_ts", values.start_ts);
  if (values.region !== "") formData.append("region", values.region);

  return apiService.postData(requestURL, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function deletePodcast(podcast_id, lectureId) {
  const requestURL = `/api/v1.0/podcasts/${podcast_id}/delete/lec/${lectureId}`;
  return apiService.getData(requestURL);
}

export function getAllCategories() {
  const requestURL = `/api/v2.0/lecture/category/all`;
  return apiService.getData(requestURL);
}

export function callDeleteRecordingAPI(recordingId) {
  const requestURL = `/api/video/${recordingId}/delete_recording`;
  return apiService.getData(requestURL);
}

export function callAddCutIntervalsAPI(recordingId, intervals) {
  const requestURL = `/api/video/${recordingId}/cut_intervals/add`;
  return apiService.postData(requestURL, {
    intervals,
  });
}

export function callSetBoundaryAPI(
  recordingId,
  start_time,
  end_time,
  reset = false
) {
  const requestURL = `/api/video/${recordingId}/set_boundary`;
  let params = {};
  if (!reset) {
    params = {
      start_time,
      end_time,
    };
  }

  return apiService.postData(requestURL, params);
}

export function callStartVideoEditAPI(recordingId) {
  const requestURL = `/api/video/recording/${recordingId}/edit`;

  return apiService.getData(requestURL);
}

export function callAddAnnotationAPI(recordingId, body) {
  const requestURL = `/api/video/${recordingId}/annotation/add`;

  return apiService.postData(requestURL, body);
}

export function callRemoveAnnotationAPI(recordingId, annoteId) {
  const requestURL = `/api/video/${recordingId}/annotation/${annoteId}/delete`;

  return apiService.getData(requestURL);
}

export function callAddNewPartAPI(recordingId, formData) {
  const requestURL = `/api/video/${recordingId}/segment/add`;

  return apiService.postData(requestURL, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function callEditPartAPI(recordingId, segmentId, formData) {
  const requestURL = `/api/video/${recordingId}/segment/${segmentId}/update`;

  return apiService.postData(requestURL, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function callDeleteSegmentFromRecordingAPI(recordingId, segmentId) {
  const requestURL = `/api/video/${recordingId}/segment/${segmentId}/delete`;

  return apiService.getData(requestURL);
}

export function addAudioToSegment(recordingId, segmentId, formData) {
  const requestURL = `/api/video/${recordingId}/segment/${segmentId}/add_audio`;

  return apiService.postData(requestURL, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
