import React from "react";
import { ThemeProvider } from "@emotion/react";
import { EtChiliTheme, ToastListener, ToastsProvider } from "@8chili/uikit";

export const UIKitProvider: React.FC<
  React.PropsWithChildren<{ theme: EtChiliTheme; children: React.ReactNode }>
> = ({ theme, children }) => {
  return (
    <ThemeProvider theme={theme}>
      <ToastsProvider>
        <ToastListener />
        {children}
      </ToastsProvider>
    </ThemeProvider>
  );
};
