import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

export const CloseIcon: React.FC<React.PropsWithChildren<SvgProps>> = (
  props
) => (
  <Svg viewBox="0 0 24 24" {...props}>
    <circle cx="12" cy="12" r="12" fill="#7E869E" fillOpacity="0.25" />
    <path
      d="M17.3333 6.66667L6.66667 17.3333"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.66667 6.66667L17.3333 17.3333"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default CloseIcon;
