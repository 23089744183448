import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect } from "react";
import { useRoutes } from "react-router-dom";
import {
  CssBaseline,
  THEME_ID,
  ThemeProvider as MuiThemeProvider,
} from "@mui/material";
import useIsDebugMode from "hooks/useIsDebugMode";
import { createCustomTheme } from "./theme";
import routes from "./routes";
import gtm from "./lib/gtm";
import useSettings from "./hooks/useSettings";
import useScrollReset from "./hooks/useScrollReset";
import useAuth from "./hooks/useAuth";
import { gtmConfig } from "./config";
import SplashScreen from "./components/SplashScreen";
import "./App.css";
import "./CssStyles/Login.css";
import "./CssStyles/Footer.css";


const App = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const IN_DEBUG_MODE = useIsDebugMode();

  const auth = useAuth();
  useScrollReset();

  useEffect(() => {
    gtm.initialize(gtmConfig);
  }, []);

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  });

  return (
    <MuiThemeProvider theme={{ [THEME_ID]: theme }}>
      <CssBaseline />
      {auth.isInitialized ? content : <SplashScreen />}
    </MuiThemeProvider>
  );
};

export default App;
