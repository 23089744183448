import styled from "@emotion/styled";
import { Theme } from "@emotion/react";
import { BaseInputProps, scales } from "./types";

interface StyledInputProps extends BaseInputProps {
  theme: Theme;
}

/**
 * Priority: Warning > Success
 */
export const getBoxShadow = ({
  isSuccess = false,
  isWarning = false,
  theme,
}: StyledInputProps) => {
  if (isWarning) {
    return theme.shadows.warning;
  }

  if (isSuccess) {
    return theme.shadows.success;
  }

  return theme.shadows.inset;
};

const getHeight = ({ scale = scales.MD }: StyledInputProps) => {
  switch (scale) {
    case scales.SM:
      return "32px";
    case scales.LG:
      return "48px";
    case scales.MD:
    default:
      return "40px";
  }
};

export const StyledTextInput = styled.input<BaseInputProps>`
  background-color: ${({ theme }) => theme.colors.input};
  box-sizing: border-box;
  border: none;
  border-radius: ${({ theme }) => theme.radii.input};
  box-shadow: ${getBoxShadow};
  color: ${({ theme }) => theme.colors.text};
  display: block;
  font-size: 16px;
  height: ${getHeight};
  outline: 0;
  padding: 0 16px;
  width: 100%;

  &:hover {
    background-color: #ffffff22;
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.textGrey};
    font-weight: 300;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.backgroundDisabled};
    box-shadow: none;
    color: ${({ theme }) => theme.colors.textSubtle};
    border: 1px solid #555;
    cursor: not-allowed;
  }

  &:focus:not(:disabled) {
    box-shadow: ${({ theme, isWarning, isSuccess }) => {
      if (isWarning) {
        return theme.shadows.warning;
      }

      if (isSuccess) {
        return theme.shadows.success;
      }
      return theme.shadows.focus;
    }};
  }
`;

StyledTextInput.defaultProps = {
  scale: scales.MD,
  isSuccess: false,
  isWarning: false,
};

export default StyledTextInput;
