import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

export const AlertWarning: React.FC<React.PropsWithChildren<SvgProps>> = (
  props
) => (
  <Svg viewBox="0 0 27 24" {...props}>
    <path
      d="M9.94828 2.03445C11.606 -0.678151 15.394 -0.678149 17.0517 2.03445L26.3295 17.2162C28.1231 20.1511 26.1079 24 22.7778 24H4.2222C0.892083 24 -1.12307 20.1511 0.670466 17.2162L9.94828 2.03445Z"
      fill="#FFA500"
    />
    <path
      d="M12.4462 15.1089C12.4462 15.7552 12.9204 16.284 13.5 16.284C14.0796 16.284 14.5538 15.7555 14.5538 15.1092V7.53864C14.5538 6.89236 14.0796 6.36354 13.5 6.36354C12.9207 6.36354 12.4464 6.89236 12.4462 7.53864V15.1089Z"
      fill="white"
    />
    <path
      d="M12.4462 18.4099C12.4462 19.0593 12.9205 19.5909 13.5 19.5909C14.0795 19.5909 14.5538 19.0593 14.5538 18.4099C14.5538 17.7604 14.0795 17.2289 13.5 17.2289C12.9205 17.2289 12.4462 17.7604 12.4462 18.4099Z"
      fill="white"
    />
  </Svg>
);

export default AlertWarning;
