import { createSvgIcon } from "@mui/material/utils";

const Question = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="24" height="24" rx="4" fill="#363636" />
    <path
      d="M9.51562 6.26985C10.2506 5.82201 11.1061 5.59808 12.0821 5.59808C12.7941 5.59808 13.4257 5.71866 13.9769 5.95981C14.5281 6.20095 14.9529 6.53397 15.2515 6.95885C15.5616 7.38373 15.7166 7.87751 15.7166 8.44019C15.7166 9.0488 15.573 9.57129 15.286 10.0077C15.0104 10.444 14.5912 10.9493 14.0285 11.5234C13.5577 12.0057 13.2017 12.4191 12.9606 12.7636C12.7195 13.0966 12.5702 13.4756 12.5128 13.9005L12.4439 14.3828H11.1692V13.6766C11.1692 13.0335 11.284 12.4938 11.5137 12.0574C11.7434 11.6096 12.0764 11.1273 12.5128 10.6105C12.8458 10.2201 13.0927 9.88134 13.2534 9.59426C13.4257 9.30717 13.5118 8.98564 13.5118 8.62966C13.5118 8.22775 13.3683 7.9177 13.0812 7.69952C12.8056 7.46985 12.4209 7.35502 11.9271 7.35502C11.1233 7.35502 10.3195 7.60191 9.51562 8.09569V6.26985ZM10.618 15.5885H13.0467V18H10.618V15.5885Z"
      fill="#999999"
    />
  </svg>,
  "Question"
);

export default Question;
