import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

export const AlertDeleted: React.FC<React.PropsWithChildren<SvgProps>> = (
  props
) => (
  <Svg viewBox="4 2 24 26" {...props}>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.00012 8C4.89555 8 4.00012 8.89543 4.00012 10V10.6667C4.00012 11.403 4.59708 12 5.33346 12C6.06984 12 6.66679 12.597 6.66679 13.3333V20C6.66679 24.4183 10.2485 28 14.6668 28H17.3335C21.7517 28 25.3335 24.4183 25.3335 20V13.3333C25.3335 12.597 25.9304 12 26.6668 12C27.4032 12 28.0001 11.403 28.0001 10.6667V10C28.0001 8.89543 27.1047 8 26.0001 8H6.00012ZM14.0001 14.6667C14.0001 13.9303 13.4032 13.3333 12.6668 13.3333C11.9304 13.3333 11.3335 13.9303 11.3335 14.6667V21.3333C11.3335 22.0697 11.9304 22.6667 12.6668 22.6667C13.4032 22.6667 14.0001 22.0697 14.0001 21.3333V14.6667ZM20.6668 14.6667C20.6668 13.9303 20.0698 13.3333 19.3335 13.3333C18.5971 13.3333 18.0001 13.9303 18.0001 14.6667V21.3333C18.0001 22.0697 18.5971 22.6667 19.3335 22.6667C20.0698 22.6667 20.6668 22.0697 20.6668 21.3333V14.6667Z"
      fill="#FF4689"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.6154 3.13191C18.5419 2.82303 17.2734 2.66663 15.9999 2.66663C14.7265 2.66663 13.4579 2.82303 12.3845 3.13191C11.3797 3.42106 10.3387 3.90215 9.76349 4.70716C9.33538 5.30631 9.47403 6.13906 10.0732 6.56717C10.6723 6.99528 11.5051 6.85663 11.9332 6.25748C11.9332 6.25751 11.9332 6.25747 11.9333 6.25735C11.9364 6.25356 12.0038 6.17176 12.218 6.05155C12.4287 5.93327 12.7284 5.80782 13.1219 5.69459C13.9114 5.46744 14.9253 5.33329 15.9999 5.33329C17.0746 5.33329 18.0885 5.46744 18.878 5.69459C19.2715 5.80782 19.5711 5.93327 19.7819 6.05155C19.9961 6.17176 20.0635 6.25357 20.0666 6.25735C20.0667 6.25747 20.0667 6.25751 20.0667 6.25748C20.4948 6.85663 21.3276 6.99528 21.9267 6.56717C22.5259 6.13906 22.6645 5.30631 22.2364 4.70716C21.6612 3.90215 20.6202 3.42105 19.6154 3.13191Z"
      fill="#FF4689"
    />
  </Svg>
);

export default AlertDeleted;
