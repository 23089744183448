import { Link as RouterLink } from "react-router-dom";
import { Button, Grid, Typography } from "@mui/material";
import { TimelineHeader as Header } from "./style";
import MoreMenu from "./MoreMenu";
import Logo from "./Logo";

const TimelineHeader = ({ recordingDetails }) => (
  <Header>
    <Grid container spacing={0}>
      <Grid item xs={6} sm={8} md={8} lg={8} className="left_side">
        <Logo />
        <Button
          className="btn"
          component={RouterLink}
          to="/video/trim"
          variant="outlined"
          color="primary"
        >
          Back
        </Button>

        <Typography color="textPrimary" variant="h4">
          {recordingDetails?.name}
        </Typography>
      </Grid>
      <Grid item xs={6} sm={4} md={4} lg={4} className="right_side">
        <span className="svg">
          <svg
            width="7"
            height="16"
            viewBox="0 0 7 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.79042 14.1332L6.56906 15.0381C5.90497 15.3002 5.37449 15.4997 4.97947 15.6369C4.58403 15.7745 4.12455 15.8429 3.60103 15.8429C2.79707 15.8429 2.17175 15.646 1.72575 15.2548C1.27976 14.862 1.05669 14.3643 1.05669 13.7606C1.05669 13.5269 1.07288 13.2865 1.10653 13.0416C1.14047 12.7963 1.19443 12.5202 1.26826 12.2115L2.0982 9.27398C2.17203 8.9927 2.23479 8.72618 2.28506 8.47415C2.33603 8.22367 2.3606 7.99322 2.3606 7.78578C2.3606 7.41064 2.28293 7.14824 2.1283 7.00071C1.97367 6.85361 1.67861 6.77892 1.24057 6.77892C1.02602 6.77892 0.805513 6.81328 0.580742 6.87973C0.354977 6.94647 0.162011 7.01093 0 7.07085L0.221931 6.16524C0.765614 5.94387 1.2853 5.75431 1.78241 5.59699C2.27952 5.43924 2.74922 5.36029 3.19351 5.36029C3.99193 5.36029 4.60802 5.55326 5.04067 5.93919C5.47332 6.3254 5.68957 6.82606 5.68957 7.44301C5.68957 7.57066 5.67537 7.79557 5.64484 8.11704C5.61502 8.43922 5.5595 8.73442 5.47857 9.00292L4.65218 11.9286C4.58445 12.1636 4.52354 12.4323 4.47058 12.7346C4.41591 13.0349 4.38978 13.2643 4.38978 13.4183C4.38978 13.8067 4.4764 14.072 4.65005 14.213C4.82499 14.354 5.12629 14.4241 5.55425 14.4241C5.75517 14.4241 5.98377 14.3885 6.23779 14.3185C6.49111 14.2485 6.67583 14.187 6.79042 14.1332ZM7 1.85014C7 2.35989 6.80789 2.79523 6.42181 3.15319C6.03673 3.51242 5.57271 3.69218 5.02988 3.69218C4.48534 3.69218 4.02018 3.51242 3.6307 3.15319C3.24193 2.79509 3.04712 2.35989 3.04712 1.85014C3.04712 1.34139 3.24193 0.905333 3.6307 0.54283C4.01947 0.180896 4.48549 0 5.02988 0C5.57257 0 6.03673 0.181322 6.42181 0.54283C6.80817 0.905333 7 1.34153 7 1.85014Z"
              fill="#999999"
            />
          </svg>
        </span>

        <Button
          className="btn"
          component={RouterLink}
          to="/video/trim"
          variant="contained"
          color="primary"
        >
          Publish
        </Button>
        <MoreMenu vertical />
      </Grid>
    </Grid>
  </Header>
);

export default TimelineHeader;
