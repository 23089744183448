export function convertToHHMMSS(secs = 0) {
  const secNum = parseInt(secs.toString(), 10);
  const hours = Math.floor(secNum / 3600);
  let hoursString = `${hours}`;
  if (hours < 10) {
    hoursString = `0${hours}`;
  }
  const minutes = Math.floor(secNum / 60) % 60;
  const seconds = secNum % 60;

  return [hoursString, minutes, seconds]
    .map((val) => (parseInt(val.toString()) < 10 ? `0${val}` : val))
    .filter((val, index) => val !== "00" || index > 0)
    .join(":")
    .replace(/^0/, "");
}

export function getSecondsFromHHMMSS(value) {
  const [str1, str2, str3] = value.split(":");

  const val1 = Number(str1);
  const val2 = Number(str2);
  const val3 = Number(str3);

  if (!isNaN(val1) && isNaN(val2) && isNaN(val3)) {
    // seconds
    return val1;
  }

  if (!isNaN(val1) && !isNaN(val2) && isNaN(val3)) {
    // minutes * 60 + seconds
    return val1 * 60 + val2;
  }

  if (!isNaN(val1) && !isNaN(val2) && !isNaN(val3)) {
    // hours * 60 * 60 + minutes * 60 + seconds
    return val1 * 60 * 60 + val2 * 60 + val3;
  }

  return 0;
}

export type TimeType = "short" | "medium" | "long";

export function getDateAndTimeFromGMTTimestamp(
  timeStamp: string,
  dateStyle: TimeType = "medium",
  timeStyle: TimeType = "medium"
) {
  return new Date(timeStamp).toLocaleString("en-US", {
    dateStyle: dateStyle,
    timeStyle: timeStyle,
  });
}
