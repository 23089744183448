import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

export const AlertSuccess: React.FC<React.PropsWithChildren<SvgProps>> = (
  props
) => (
  <Svg viewBox="0 0 24 24" {...props}>
    <ellipse cx="11.8931" cy="12" rx="11.8931" ry="12" fill="#03C680" />
    <path
      d="M10.2926 17.5385C10.0385 17.5385 9.78438 17.4345 9.59024 17.226L5.78014 13.1345C5.3923 12.7179 5.3923 12.0424 5.78014 11.6258C6.16798 11.2094 6.79713 11.2094 7.18497 11.6258L10.2462 14.9132C10.272 14.9409 10.3133 14.9409 10.3391 14.9132L17.0589 7.69701C17.4468 7.28052 18.0759 7.28052 18.4638 7.69701C18.8516 8.11347 18.8516 8.78914 18.4638 9.20559L10.995 17.226C10.8014 17.4345 10.5468 17.5385 10.2926 17.5385Z"
      fill="#FCF2FF"
    />
  </Svg>
);

export default AlertSuccess;
