import { styled } from "@mui/material/styles";
import Slider from "@mui/material/Slider";
import Box from "@mui/material/Box";

const CustomeSlider = styled(Slider)({
  color: "#ff852c",
  "& .MuiSlider-track": {
    height: 5,
    borderRadius: 5,
    color: "#ff852c",
  },
  "& .MuiSlider-thumb": {
    marginTop: -4,
  },
  "& .MuiSlider-rail": {
    height: 5,
    borderRadius: 5,
    color: "#ff852c",
  },
});

function TimeSlider({ value, setvalue }) {
  const handleTimeRange = (e) => {
    setvalue(e.target.value);
  };

  return (
    <div>
      <CustomeSlider
        min={3}
        max={15}
        value={value}
        defaultValue={3}
        aria-label="Default"
        onChange={handleTimeRange}
        valueLabelDisplay="off"
      />
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          paddingBottom: "10px",
        }}
      >
        {value} sec
      </Box>
    </div>
  );
}

export default TimeSlider;
