import Styled from "styled-components";

const DropDown = Styled.div`
font-family: 'Mulish', sans-serif;
background: #262626;
box-shadow: -17px 17px 14px rgba(0, 0, 0, 0.1);
border-radius: 5px;
padding-bottom:16px;
padding-top:16px;

.box_header {
    display:flex;
    align-items:center;
    background: linear-gradient(262.1deg, #0083FC 2.5%, #0065FC 99.99%);
    border-radius: 5.45153px;
    margin:15px 15px 6px 15px;
    padding: 32px 19px;


     h2 {
        font-weight: 800;
        font-size: 16.4055px;
        
     }

     h6 {
 
        font-size: 12.5059px;
        color: #FFFFFF;
     }
    svg {
        margin-right:10px;
    }
}

.box_body {

     margin:0px 15px 0px 15px;

 
         
     
          
            a {
                text-decoration:none;
                border-bottom: 0.56982px solid rgba(255, 255, 255, 0.12);
                padding: 10px 16px;


                h6 {
                    transition:0.2s;
                     font-size: 15px;
                     line-height: 19px;
                }

            
                
            }

           

    

}

.box_footer {
      margin:0px 15px;
    div  {
        background-color:#2F2F2F;
        padding:18px 15px ;
        display:flex;
        justify-content:space-between;
        align-items:center;
        border-radius: 5px;

        h6 {
            font-size: 15px;
            line-height: 19px;
        }

        label {

            .MuiTypography-body1 {
                display:none ;
            }
        }
    }
    
    
}


`;

const SearchWrapper = Styled.div`
  background-color:#262626;
  font-family: 'Mulish', sans-serif;
  .MuiOutlinedInput-root  {
    background: #151515;
    border-radius: 5px;
    border:none !important;
    font-weight: 300;
    font-size: 22px;
    color: rgba(255, 255, 255, 0.6);
    .arrow_icon svg, .search_icon svg {
        color: rgba(255, 255, 255, 0.4) !important;
    }
    .arrow_icon  {
        cursor:pointer;
    }
    .search_icon svg {
       
        margin-right:10px;
        margin-top:7px;
    }

    &:focus: {
        outline:none !important;
    }

    .MuiOutlinedInput-notchedOutline {
        border:none !important
    }
  }



  .search_result {
      margin-top:39px;

      h4 {
        color: rgba(255, 255, 255, 0.9);
        font-weight: 600;
        font-size: 26px;
        margin-bottom:16px;
      }

      ul {
          list-style-type:none;
          padding:0;

        li {
            padding-bottom:20px;
            &:last-child {
                padding-bottom:0px;
            }
            a {
                font-weight: normal;
                color: rgba(255, 255, 255, 0.9);
                font-size: 18px;
                text-decoration:none;
            }
        }
      }
 

      .last_anchor {

        a {
            svg {
                margin-right:3px;
            }
            justify-content: flex-end;
            display:flex;
            text-decoration:none;
            font-size: 18px;
            color: #FF852C;
        }
      }


  }

`;

const CategoryWrapper = Styled.div`

.MuiDrawer-paper  {
    padding-top:93px;
    padding-bottom:93px;
}


.pop_button {
      
    &:hover {
        background:none !important;
    }
}

       .open_modal {
        padding:9px 14px 11px 14px;
        display:flex;
        justify-content:space-between;
        align-items:center;
        background: #232323;
        border-radius: 3.69767px;
        span {
        font-weight: 600;
        font-size: 15px;
        color: rgba(255, 255, 255, 0.9);
        }
       
    }

   
                 


`;

const ListStyle = Styled.ul`
font-family: 'Mulish', sans-serif;
list-style-type:none;
padding:0;
li {
    padding-bottom:20px;
    &:last-child {
        padding-bottom:0px;

    }
    a {
        font-weight: normal;
        color: rgba(255, 255, 255, 0.9);
        font-size: 18px;
        text-decoration:none;
    }
}

`;

const ContentWrapper = Styled.div`
   
padding:0px 124px;

@media(max-width:767px) {
    padding:0px 30px;
}

`;

export { DropDown, SearchWrapper, CategoryWrapper, ListStyle, ContentWrapper };
