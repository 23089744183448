import { m as motion, Variants } from "framer-motion";
import styled from "@emotion/styled";
import {
  background,
  border,
  layout,
  position,
  space,
  color,
  compose,
  textStyle,
} from "styled-system";
import { BoxProps } from "./types";

export { AnimatePresence, LazyMotion, domAnimation } from "framer-motion";
export type MotionVariants = Variants;

export const MotionBox = styled(motion.div)<BoxProps>`
  ${compose(background, border, layout, position, space)}
`;

const Box = styled.div<BoxProps>`
  ${compose(background, border, layout, position, space, color, textStyle)}
`;

export default Box;
