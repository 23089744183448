/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import Styled from "styled-components";
import React, { forwardRef, useContext, useEffect } from "react";
import VideoContext from "../../../contexts/video";

export const Video = Styled.video`
  filter: ${(props) => props.filter}
`;

type VideoPlayerPropsObject = {
  /**
   * Video URL to render
   */
  src: string;
  /**
   * Maximum time in seconds. This will be the duration which the video is allowed to played.
   */
  max: number;
  /**
   * Minimum time in seconds. Video will start from this time
   */
  min: number;
  /**
   * Funtion which will execute when the video is loaded into the component
   */
  onLoad: Function;
  /**
   * Funtion which will execute on each frame.
   */
  onTimeUpdate: Function;
  /**
   * Funtion which will be executed when the video is paused.
   */
  onPause: Function;
  onPlay: Function;
  colorCorrection: any;
  videoPaused: boolean;
  showControls?: boolean;
};

const VideoPlayer = forwardRef(function VideoPlayerComponent(
  {
    src,
    max,
    min,
    colorCorrection,
    onLoad,
    onTimeUpdate,
    onPause,
    onPlay,
    videoPaused,
    showControls = true,
  }: VideoPlayerPropsObject,
  ref,
) {
  const { trim, recordingDetails, videoDispatcher } = useContext(VideoContext);
  useEffect(() => {
    /* @ts-ignore */
    if (ref && ref.current) {
      /* @ts-ignore */
      ref.current.currentTime = min;
    }
  }, [min, ref]);

  const handleVideoOnLoad = ({ target }) => {
    videoDispatcher({
      type: "UPDATE_ORG_VIDEO_DURATION",
      payload: target.duration,
    });
    if (recordingDetails && !recordingDetails?.endTime) {
      videoDispatcher({
        type: "UPDATE_VIDEO_DURATION",
        payload: target.duration,
      });
    }
    onLoad?.({ duration: target.duration });
  };

  const handleTimeUpdate = ({ target }) => {
    onTimeUpdate?.(target);
    if (Boolean(max) && Math.floor(target.currentTime) >= Math.floor(max)) {
      target.currentTime = min;
      target.pause();
    }
  };

  const handleVideoPause = ({ target }) => {
    onPause?.(target);
  };

  const handleVideoPlay = ({ target }) => {
    onPlay?.(target);
  };

  const handleSeeked = ({ target }) => {
    if (trim.startTime > target.currentTime) {
      target.currentTime = trim.startTime;
    }

    if (target.currentTime >= trim.endTime) {
      target.currentTime = trim.endTime;
    }
  };

  const filter =
    colorCorrection && colorCorrection.brightness && colorCorrection.contrast
      ? `brightness(${colorCorrection.brightness}%) contrast(${colorCorrection.contrast}%)`
      : colorCorrection && colorCorrection.brightness
      ? `brightness(${colorCorrection.brightness}%)`
      : "unset";

  return (
    <React.Fragment>
      <Video
        paused={videoPaused}
        id="video_duration"
        filter={filter}
        ref={ref}
        width="100%"
        height="100%"
        controls={showControls}
        src={src}
        onLoadedData={handleVideoOnLoad}
        onVolumeChange={handleVideoOnLoad}
        onTimeUpdate={handleTimeUpdate}
        onPause={handleVideoPause}
        onPlay={handleVideoPlay}
        onSeeked={handleSeeked}
        disablePictureInPicture
        disableRemotePlayback
        controlsList="nodownload noremoteplayback noplaybackrate captions"
      ></Video>
    </React.Fragment>
  );
});

export default VideoPlayer;
