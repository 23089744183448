import { createSvgIcon } from "@mui/material/utils";

const CutIcon = createSvgIcon(
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.378 2.98169C18.4826 1.7025 16.7023 1.36539 15.4233 2.26109L8.847 6.86738L6.45568 5.19221C7.34608 3.6544 6.92726 1.66441 5.44477 0.626129C3.87924 -0.470083 1.72226 -0.0896606 0.625969 1.4761C-0.469834 3.04193 -0.0898435 5.20009 1.47572 6.29675C2.54999 7.04919 3.90316 7.1056 5.00043 6.56109L7.92939 8.61256L5.0007 10.6638C3.90342 10.1196 2.55033 10.176 1.47599 10.9281C-0.0895028 12.0248 -0.469531 14.1827 0.626233 15.7485C1.72253 17.3146 3.87973 17.695 5.44499 16.5988C6.9273 15.5605 7.34604 13.5705 6.4559 12.0327L8.84723 10.3575L15.4233 14.9635C16.7023 15.8595 18.4826 15.5222 19.378 14.2429L11.3387 8.61252L19.378 2.98169ZM3.46787 5.26331C2.47728 5.26331 1.6742 4.45995 1.6742 3.46903C1.6742 2.47811 2.47732 1.67475 3.46787 1.67475C4.45868 1.67475 5.26155 2.47811 5.26155 3.46903C5.26155 4.45995 4.45868 5.26331 3.46787 5.26331ZM3.46787 15.5505C2.47728 15.5505 1.6742 14.7472 1.6742 13.7562C1.6742 12.7653 2.47728 11.962 3.46787 11.962C4.45868 11.962 5.26155 12.7653 5.26155 13.7562C5.26155 14.7471 4.45868 15.5505 3.46787 15.5505Z"
      fill="currentColor"
    />
  </svg>,
  "CutIcon"
);

export default CutIcon;
