import { useState } from "react";
import PropTypes from "prop-types";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { Button, Typography } from "@mui/material";
import { DescriptionBox } from "./trimStyles";

const Description = ({ description: descrp, mode, onSave, onClose }) => {
  const [description, setDescription] = useState(descrp);

  return (
    <DescriptionBox>
      <TextareaAutosize
        aria-label="minimum height"
        minRows={3}
        placeholder="Type here.."
        value={description}
        onChange={({ target }) => setDescription(target.value)}
      />
      <br />

      {mode === "edit" && (
        <>
          <Button className="saveBtn" onClick={() => onSave(description)}>
            <Typography color="textPrimary" variant="h6">
              Save
            </Typography>
          </Button>
          <Button onClick={onClose} className="closebtn">
            Close
          </Button>
        </>
      )}
    </DescriptionBox>
  );
};

Description.propTypes = {
  /**
   * Text in the box
   */
  description: PropTypes.string,

  /**
   * Callback called on click of close button
   */
  onClose: PropTypes.func,

  /**
   * Callback called onclick of save button
   */
  onSave: PropTypes.func,
};

export default Description;
