import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";
import { convertToHHMMSS, getSecondsFromHHMMSS } from "../../utils/timeUtils";
import { DateWraper } from "../../components/video/trimStyles";
import { useToast } from "@8chili/uikit";

const CutTimeField = ({ timeInSec, onChange, max, min, ...props }) => {
  const [time, setTime] = useState(convertToHHMMSS(timeInSec));
  const { toastError } = useToast();

  useEffect(() => {
    setTime(convertToHHMMSS(timeInSec));
    if (timeInSec >= min && timeInSec <= max) {
      setTime(convertToHHMMSS(timeInSec));
    } else if (timeInSec > max) {
      setTime(convertToHHMMSS(max));
    } else if (timeInSec < min) {
      setTime(convertToHHMMSS(min));
    }
  }, [timeInSec, max, min]);

  const handleOnChange = ({ target }) => {
    setTime(target.value);
  };

  const handleOnBlur = ({ target }) => {
    const seconds = Math.max(0, getSecondsFromHHMMSS(target.value));
    if (seconds >= min && seconds <= max) {
      setTime(convertToHHMMSS(seconds));
      onChange(seconds);
    } else if (seconds > max) {
      setTime(convertToHHMMSS(max));
      onChange(max);
    } else if (seconds < min) {
      toastError("Start time cannot be lower the current time ");
      setTime(convertToHHMMSS(min));
      onChange(min);
    }
  };

  const onPressEnter = (e) => {
    if (e.key === "Enter") {
      handleOnBlur(e);
    }
  };

  const handleOnFocus = ({ target }) => {
    target.select();
  };

  return (
    <DateWraper>
      <TextField
        value={time}
        onKeyDown={onPressEnter}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        onFocus={handleOnFocus}
        {...props}
      />
    </DateWraper>
  );
};

CutTimeField.propTypes = {
  /**
   * Time in seconds
   */
  timeInSec: PropTypes.number.isRequired,

  /**
   * Shows the time format to be inputed
   * eg. hh:mm:ss
   */
  placeholder: PropTypes.string,

  /**
   * Called whenever the input changes
   */
  onChange: PropTypes.func,

  /**
   * Minimum time in seconds. Video will start from this time
   */
  min: PropTypes.number,
  /**
   * Maximum time in seconds. This will be the duration which the video is allowed to played.
   */
  max: PropTypes.number,
};

export default CutTimeField;
