import { Grid, Typography } from "@mui/material";
import { VideoFooter } from "./trimStyles";

const VideoSectionFooter = () => (
  <VideoFooter>
    <Grid container spacing={2}>
      <Grid item xs={12} lg={6} sm={6}>
        <Typography color="textPrimary" variant="h2">
          &#169; 8chili Inc. All rights reserved.
        </Typography>
      </Grid>
      <Grid item xs={12} lg={6} sm={6} className="footer-nav">
        <Typography mr={2} variant="h6">
          Terms of service
        </Typography>
        <Typography mr={5} variant="h6">
          Privacy policy
        </Typography>
      </Grid>
    </Grid>
  </VideoFooter>
);

export default VideoSectionFooter;
