import React, { useReducer } from "react";
import PropTypes from "prop-types";
import { VideoContextModel } from "./types/VideoContextModel";
import axios from "../utils/axiosInstance";
import { callAddCutIntervalsAPI, callSetBoundaryAPI } from "../apis/LectureApi";

// TODO: Move this to after login
const auth_token = localStorage.getItem("auth_token");
axios.defaults.headers.common.Authorization = auth_token;

export const removeDuplicates = (array, key) => [
  ...new Map(array.map((item) => [item[key], item])).values(),
];

const calculatePreviewData = (rawData) => {
  const events = Object.keys(rawData).reduce((prev, curr) => {
    if (["cut", "annote", "color", "voices", "parts"].includes(curr)) {
      const history = curr === "parts" ? rawData[curr] : rawData[curr].history;
      const startTimes = history.map((his) => his.startTime);
      const endTimes = history.map((his) => his.endTime);
      const raw = history.map((his) => his);
      const prevClone = { ...prev };
      startTimes.forEach(
        (time, index) =>
          (prevClone[
            `${time}-${curr === "annote" ? raw[index].type : curr}-${
              endTimes[index]
            }`
          ] = {
            type: curr,
            startTime: time,
            endTime: endTimes[index],
            raw: raw[index],
          })
      );
      return prevClone;
    }
    return prev;
  }, {});
  const triggerArray = Object.keys(events);
  return { triggerArray, events };
};

const initialState = {
  videoDuration: 0,
  orignalVideoDuration: 0,
  calculatedDuration: 0,
  trim: {
    startTime: 0,
    endTime: 0,
    history: null,
  },
  cut: {
    history: [],
  },
  annote: {
    history: [],
  },
  color: {
    history: [],
  },
  lectureDetails: null,
  recordingDetails: null,
  segment: null,
  courseDetails: [],
  preview: null,
  parts: [],
  watched: [],
  voices: {
    history: [],
  },
  new_parts: {
    history: [],
  },
  recordVoice: false,
  voiceOver: null,
};

const REDUCERS = {
  UPDATE_VIDEO_DURATION: (state, action) => ({
    ...state,
    videoDuration: action.payload,
  }),
  UPDATE_ORG_VIDEO_DURATION: (state, action) => ({
    ...state,
    orignalVideoDuration: action.payload,
  }),
  UPDATE_DURATION: (state, action) => ({
    ...state,
    calculatedDuration: action.payload,
  }),

  UPDATE_TRIM_TIMER: (state, action) => {
    const { startTime, endTime } = action.payload;
    return {
      ...state,
      trim: {
        ...state.trim,
        startTime,
        endTime,
      },
    };
  },
  UPDATE_TRIM_HISTORY: (state, action) => {
    const { startTime, endTime } = action.payload;

    return {
      ...state,
      trim: {
        ...state.trim,
        history: {
          startTime,
          endTime,
        },
      },
    };
  },

  REMOVE_TRIM_HISTORY: (state) => ({
    ...state,
    trim: {
      ...state.trim,
      history: null,
    },
  }),
  UPDATE_CUT_HISTORY: (state, action) => {
    const history = [...state.cut.history, ...action.payload];

    const updatedState = {
      ...state,
      cut: {
        history: removeDuplicates(history, "index"),
      },
    };
    return { ...updatedState, preview: calculatePreviewData(updatedState) };
  },
  REMOVE_CUT_HISTORY: (state, action) => {
    const history = state.cut.history.slice();
    history.splice(action.payload, 1);
    const updatedState = {
      ...state,
      cut: {
        ...state.cut,
        history,
      },
    };
    return { ...updatedState, preview: calculatePreviewData(updatedState) };
  },
  UPDATE_ANNOTE_HISTORY: (state, action) => {
    const history = [...state.annote.history, ...action.payload];
    const updatedState = {
      ...state,
      annote: {
        history: removeDuplicates(history, "id"),
      },
    };
    return { ...updatedState, preview: calculatePreviewData(updatedState) };
  },
  REMOVE_ANNOTE_HISTORY: (state, action) => {
    const history = state.annote.history.slice();
    history.splice(action.payload, 1);
    const updatedState = {
      ...state,
      annote: {
        history,
      },
    };
    return { ...updatedState, preview: calculatePreviewData(updatedState) };
  },
  SET_LECTURE_DETAILS: (state, action) => ({
    ...state,
    lectureDetails: action.payload,
  }),
  SET_COURSE_DETAILS: (state, action) => ({
    ...state,
    courseDetails: action.payload,
  }),

  SET_RECORDING_DETAILS: (state, action) => ({
    ...state,
    recordingDetails: action.payload,
  }),

  SET_SEGMENT_DETAILS: (state, action) => ({
    ...state,
    segment: action.payload,
  }),

  UPDATE_COLOR_HISTORY: (state, action) => {
    const history = [...state.color.history, ...action.payload];
    const updatedState = {
      ...state,
      color: {
        ...state.color,
        history: removeDuplicates(history, "id"),
      },
    };
    return { ...updatedState, preview: calculatePreviewData(updatedState) };
  },
  REMOVE_COLOR_HISTORY: (state, action) => {
    const history = state.color.history.slice();
    history.splice(action.payload, 1);
    const updatedState = {
      ...state,
      color: {
        history,
      },
    };
    return { ...updatedState, preview: calculatePreviewData(updatedState) };
  },
  ADD_PART: (state, action) => {
    const parts = [...state.parts, action.payload];

    const updatedState = {
      ...state,
      parts: removeDuplicates(parts, "id"),
    };
    return { ...updatedState, preview: calculatePreviewData(updatedState) };
  },
  REPLACE_ADD_PART: (state, action) => {
    const updatedState = {
      ...state,
      parts: action.payload,
    };
    return { ...updatedState, preview: calculatePreviewData(updatedState) };
  },

  ADD_VOICE: (state, action) => {
    const newHistoryList = [];
    const payloadVoicesProcessed = [];

    for (let i = 0; i < state.voices.history.length; i++) {
      let found = false;
      const currentVoice = state.voices.history[i];
      for (let j = 0; j < action.payload.length; j++) {
        const actionPayload = action.payload[j];
        if (actionPayload.id === currentVoice.id) {
          const newHistory = { ...currentVoice, ...actionPayload };
          newHistoryList.push(newHistory);
          payloadVoicesProcessed.push(actionPayload.id);
          found = true;
          break;
        }
      }
      if (found === false) {
        newHistoryList.push(currentVoice);
      }
    }

    for (let i = 0; i < action.payload.length; i++) {
      const currentActionPayload = action.payload[i];
      if (payloadVoicesProcessed.indexOf(currentActionPayload.id) === -1) {
        newHistoryList.push(currentActionPayload);
      }
    }

    const updatedState = {
      ...state,
      voices: {
        history: newHistoryList,
      },
    };

    return { ...updatedState, preview: calculatePreviewData(updatedState) };
  },

  ADD_PARTS: (state, action) => {
    const history = [...state.voices.history, ...action.payload];

    const updatedState = {
      ...state,
      new_parts: {
        history: removeDuplicates(history, "id"),
      },
    };

    return { ...updatedState, preview: calculatePreviewData(updatedState) };
  },

  REMOVE_VOICE: (state, action) => {
    const history = state.voices.history.slice();
    history.splice(action.payload, action.size);
    const updatedState = {
      ...state,
      voices: {
        history,
      },
    };
    return { ...updatedState, preview: calculatePreviewData(updatedState) };
  },

  VOICE_STARTED: (state, action) => {
    const updatedState = {
      ...state,
      recordVoice: action.payload,
    };
    return { ...updatedState, preview: calculatePreviewData(updatedState) };
  },

  VOICE_OVER: (state, action) => {
    const updatedState = {
      ...state,
      voiceOver: action.payload,
    };
    return { ...updatedState, preview: calculatePreviewData(updatedState) };
  },
};
const reducerHandler = (state, action) =>
  REDUCERS[action.type] ? REDUCERS[action.type](state, action) : state;

const videoConfigs = {
  ...initialState,
  videoDispatcher: () => undefined,
  applyCut: () => Promise.resolve(),
  applyTrim: () => Promise.resolve(),
};

const VideoContext = React.createContext<VideoContextModel>(videoConfigs);

export const VideoProvider = ({ children }) => {
  const [configs, videoDispatcher] = useReducer(reducerHandler, initialState);

  const applyTrim = async (startTime, endTime, reset) => {
    const recordingId = localStorage.getItem("recording_id");

    await callSetBoundaryAPI(recordingId, startTime, endTime, reset).then(
      (res) => {
        if (res) {
          if (reset) {
            videoDispatcher({
              type: "REMOVE_TRIM_HISTORY",
              payload: { startTime, endTime },
            });
          } else {
            videoDispatcher({
              type: "UPDATE_TRIM_HISTORY",
              payload: { startTime, endTime },
            });
          }
        }
      }
    );
  };
  const applyCut = async (startTime, endTime) => {
    const histories = [...initialState.cut.history];
    const recordingId = localStorage.getItem("recording_id");

    await callAddCutIntervalsAPI(recordingId, histories).then((res) => {
      if (res) {
        videoDispatcher({
          type: "UPDATE_CUT_HISTORY",
          payload: { startTime, endTime },
        });
      }
    });
  };
  return (
    <VideoContext.Provider
      value={{
        ...configs,
        videoDispatcher,
        applyTrim,
        applyCut,
      }}
    >
      {children}
    </VideoContext.Provider>
  );
};

VideoProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default VideoContext;
