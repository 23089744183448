import styled from "@emotion/styled";
import { TypographyProps, compose, flexbox, typography } from "styled-system";
import Box from "./Box";
import { FlexProps } from "./types";

const Flex = styled(Box)<
  FlexProps & TypographyProps & { gap?: React.CSSProperties["gap"] }
>`
  display: flex;
  gap: ${({ gap }) => gap};

  ${compose(flexbox, typography)}
`;

export default Flex;
