import { lazy, Suspense } from "react";
import { VideoProvider } from "./contexts/video";
import AdditionalInfoContextProvider from "./contexts/AdditionalInfoFlow/AdditionalInfoContextProvider";
import VideoLayout from "./components/VideoLayout";
import TimelineLayout from "./components/TimelineLayout";
import ServicesTerms from "./components/ServicesTerms";
import PrivacyPolicy from "./components/PrivacyPolicy";
import LoadingScreen from "./components/LoadingScreen";
import GuestGuard from "./components/GuestGuard";
import DashboardLayout from "./components/dashboard/DashboardLayout";
import AuthGuard from "./components/AuthGuard";

const Loadable = (Component) =>
  function InnerLoadable(props) {
    return (
      <Suspense fallback={<LoadingScreen />}>
        <Component {...props} />
      </Suspense>
    );
  };

const Enrollment = Loadable(lazy(() => import("./pages/forms/enrollUser")));
const LoginEnrollment = Loadable(
  lazy(() => import("./pages/forms/loginEnrollment"))
);
const RegisterEnrollment = Loadable(
  lazy(() => import("./pages/forms/registerEnrollment"))
);

// * Authentication pages

const Login = Loadable(lazy(() => import("./pages/authentication/Login")));
const AdditionalDataFlow = Loadable(
  lazy(() => import("./pages/AdditionalDataFlow"))
);
const ThanksYouPage = Loadable(
  lazy(() => import("./pages/authentication/ThanksYouPage"))
);
const PasswordRecovery = Loadable(
  lazy(() => import("./pages/authentication/PasswordRecovery"))
);
const PasswordReset = Loadable(
  lazy(() => import("./pages/authentication/PasswordReset"))
);
const Register = Loadable(
  lazy(() => import("./pages/authentication/Register"))
);
const VerifyCode = Loadable(
  lazy(() => import("./pages/authentication/VerifyCode"))
);
const SetPassword = Loadable(
  lazy(() => import("./pages/authentication/SetPassword"))
);

// * Dashboard pages

const MainDashboard = Loadable(
  lazy(() => import("./pages/dashboard/MainDashboard"))
);

const MyContent = Loadable(lazy(() => import("./pages/dashboard/MyContent")));

const BrowseContent = Loadable(
  lazy(() => import("./pages/browse/BrowseContent"))
);

const UserList = Loadable(lazy(() => import("./pages/dashboard/UserDetails")));
const UserDetails = Loadable(
  lazy(() => import("./components/userDetail/UserData"))
);

// * Error pages
//  * Do define routes for these pages
// const AuthorizationRequired = Loadable(
//   lazy(() => import("./pages/AuthorizationRequired"))
// );
// const NotFound = Loadable(lazy(() => import("./pages/NotFound")));
// const ServerError = Loadable(lazy(() => import("./pages/ServerError")));

// * Create Course and Lecutre Pages

const CreateCourse = Loadable(lazy(() => import("./pages/forms/CreateCourse")));
const CreateLecture = Loadable(
  lazy(() => import("./pages/forms/CreateLecture"))
);
const CreateProcedure = Loadable(
  lazy(() => import("./pages/forms/CreateProcedure"))
);

// * Course Detail Page

const CourseDetail = Loadable(
  lazy(() => import("./pages/dashboard/CourseDetail"))
);

// * Lecture Detail Page

const LectureDetail = Loadable(
  lazy(() => import("./pages/dashboard/LectureDetail"))
);

const ModuleDetail = Loadable(
  lazy(() => import("./pages/dashboard/ModuleDetail"))
);

const ProcedureDetail = Loadable(
  lazy(() => import("./pages/dashboard/ProcedureDetails"))
);

// * My Profile Page

const Profile = Loadable(
  lazy(() => import("./pages/profile/ProfileSubscribe"))
);

// * video Pages

const Trim = Loadable(lazy(() => import("./pages/videos/Trim")));

const Cut = Loadable(lazy(() => import("./pages/videos/NewCut")));

const Annotations = Loadable(
  lazy(() => import("./pages/videos/NewAnnotations"))
);
const Colors = Loadable(lazy(() => import("./pages/videos/Colors")));

const VideoAndPic = Loadable(
  lazy(() => import("./pages/videos/VideoAndPictures"))
);
const LeactureParts = Loadable(
  lazy(() => import("./pages/videos/NewLectureParts"))
  // lazy(() => import("./pages/videos/LeactureParts"))
);

const Timeline = Loadable(lazy(() => import("./pages/videos/Timeline")));

const routes = [
  //   * Authentication Routes
  {
    path: "/",
    children: [
      {
        path: "enroll",
        element: <Enrollment />,
      },
      {
        path: "enroll-login",
        element: <LoginEnrollment />,
      },
      {
        path: "enroll-register",
        element: <RegisterEnrollment />,
      },
      {
        path: "enroll-thank-you",
        element: <ThanksYouPage />,
      },
      ...["/", "login"].map((p) => ({
        path: p,
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      })),
      {
        path: "login-unguarded",
        element: <Login />,
      },
      {
        path: "password-recovery",
        element: <PasswordRecovery />,
      },
      {
        path: "password-reset",
        element: <PasswordReset />,
      },
      {
        path: "register",
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        ),
      },

      {
        path: "enroll",
        element: (
          <GuestGuard>
            <Enrollment />
          </GuestGuard>
        ),
      },
      {
        path: "register-unguarded",
        element: <Register />,
      },
      {
        path: "verify-code",
        element: (
          <AuthGuard>
            <VerifyCode />
          </AuthGuard>
        ),
      },
      {
        path: "policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "terms_and_condition",
        element: <ServicesTerms />,
      },
      {
        path: "set_password",
        element: <SetPassword />,
      },
    ],
  },
  //   * Authentication Routes

  //   * Dashboard Routes
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout title={undefined} />
      </AuthGuard>
    ),
    children: [
      {
        path: "/dashboard",
        element: <MainDashboard />,
      },
    ],
  },

  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout title="My Content" />
      </AuthGuard>
    ),
    children: [
      {
        path: "mycontent",
        element: <MyContent />,
      },
    ],
  },
  {
    path: "additional-info",
    element: (
      <AuthGuard>
        <AdditionalInfoContextProvider>
          <AdditionalDataFlow />
        </AdditionalInfoContextProvider>
      </AuthGuard>
    ),
  },

  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout title="Browse" />
      </AuthGuard>
    ),
    children: [
      {
        path: "browse",
        element: <BrowseContent />,
      },
    ],
  },
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout title="User Details" />
      </AuthGuard>
    ),
    children: [
      {
        path: "user",
        element: <UserList />,
      },
      {
        path: "userdetail",
        element: <UserDetails />,
      },
    ],
  },
  //   * Dashboard Routes

  //  * Course Detail Route

  {
    path: "dashboard/",
    element: (
      <AuthGuard>
        <DashboardLayout title="Course details" />
      </AuthGuard>
    ),
    children: [
      {
        path: "course-detail",
        element: <CourseDetail />,
      },
    ],
  },

  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout title="Lecture details" />
      </AuthGuard>
    ),
    children: [
      {
        path: "lecture-detail",
        element: <LectureDetail />,
      },
    ],
  },
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout title="Machine details" />
      </AuthGuard>
    ),
    children: [
      {
        path: "module-detail",
        element: <ModuleDetail />,
      },
    ],
  },
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout title="My Profile" />
      </AuthGuard>
    ),
    children: [
      {
        path: "profile",
        element: <Profile />,
      },
    ],
  },

  //  * Course Detail Route

  //  * Procedure Detail Route
  {
    path: "dashboard",
    element: (
      <VideoProvider>
        <VideoLayout no_sidebar={undefined} />
      </VideoProvider>
    ),
    children: [
      {
        path: "procedure-detail",
        element: <ProcedureDetail />,
      },
    ],
  },
  //  * Procedure Detail Route

  //  * Create Course and Lecture Routes
  {
    path: "create/",
    children: [
      {
        path: "course",
        element: <CreateCourse />,
      },
      {
        path: "lecture/",
        element: <CreateLecture />,
      },
      {
        path: "procedure/",
        element: <CreateProcedure />,
      },
    ],
  },
  //  * Create Course and Lecture Routes

  // *  Video Screen Routes
  {
    path: "video/",
    element: (
      <VideoProvider>
        <VideoLayout no_sidebar={undefined} />
      </VideoProvider>
    ),
    children: [
      {
        path: "trim",
        element: <Trim />,
      },
      {
        path: "cut",
        element: <Cut />,
      },
      {
        path: "annotations",
        element: <Annotations />,
      },
      {
        path: "colors",
        element: <Colors />,
      },
      {
        path: "edit",
        element: <VideoAndPic />,
      },
      {
        path: "lecture_parts",
        element: <LeactureParts />,
      },
    ],
  },

  {
    path: "video/",
    element: (
      <VideoProvider>
        <TimelineLayout />
      </VideoProvider>
    ),
    children: [
      {
        path: "timeline",
        element: <Timeline />,
      },
    ],
  },
  // *  Video Screen Routes
];

export default routes;
