import styled from "@emotion/styled";
import { Theme } from "@emotion/react";
import { AlertProps, alertVariants } from "./type";

import AlertSuccess from "../Svg/icons/AlertSuccess";
import AlertWarning from "../Svg/icons/AlertWarning";
import AlertError from "../Svg/icons/AlertError";
import AlertInfo from "../Svg/icons/AlertInfo";
import AlertAdded from "../Svg/icons/AlertAdded";
import AlertDeleted from "../Svg/icons/AlertDeleted";
import { Flex } from "../Box";
import CloseIcon from "../Svg/icons/CloseIcon";
import { Text } from "../Text";

interface AlertVariantColorProps {
  theme: Theme;
  variant?: AlertProps["variant"];
}

const getVariantColors = ({
  theme,
  variant = alertVariants.INFO,
}: AlertVariantColorProps) => {
  switch (variant) {
    case alertVariants.ERROR:
      return theme.colors.failure;
    case alertVariants.WARNING:
      return theme.colors.alertWarning;
    case alertVariants.SUCCESS:
      return theme.colors.success;
    case alertVariants.INFO:
      return theme.colors.alertInfo;
    case alertVariants.ADDED:
      return theme.colors.alertAdded;
    case alertVariants.DELETED:
      return theme.colors.alertDeleted;
    default:
      return theme.colors.alertInfo;
  }
};

const getAlertIcon = (variant: AlertProps["variant"]) => {
  switch (variant) {
    case alertVariants.SUCCESS:
      return () => <AlertSuccess />;
    case alertVariants.WARNING:
      return () => <AlertWarning />;
    case alertVariants.ERROR:
      return () => <AlertError />;
    case alertVariants.INFO:
      return () => <AlertInfo />;
    case alertVariants.ADDED:
      return () => <AlertAdded />;
    case alertVariants.DELETED:
      return () => <AlertDeleted />;
    default:
      return () => <AlertInfo />;
  }
};

const StyledAlert = styled.div<{ variant: AlertProps["variant"] }>`
  display: flex;
  position: relative;
  box-sizing: border-box;

  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.backgroundContent};
  box-shadow: ${({ theme }) => theme.shadows.active};
  backdrop-filter: blur(1px);
  border: none;
  border-radius: 5px;
  box-shadow: 0 0 10px black;
  width: 300px;
`;

const IconWrapper = styled.div``;

const AlertTitleWrapper = styled.div<{ variant: AlertProps["variant"] }>`
  font-size: 0.875rem;
  font-weight: bold;

  color: ${({ theme, variant }) => getVariantColors({ theme, variant })};
`;

const AlertDescriptionWrapper = styled.div`
  font-size: 0.75rem;
  color: #ffffffc9;
`;

const CloseIconWrapper = styled.div`
  position: absolute;
  right: -6px;
  top: -8px;

  cursor: pointer;

  :hover {
    display: inline-block;
  }
`;

// TODO - Need to add actions
const Alert = ({ variant = "info", title, children, onClick }: AlertProps) => {
  const Icon = getAlertIcon(variant);

  return (
    <StyledAlert variant={variant}>
      <IconWrapper>{<Icon />}</IconWrapper>
      <Flex flexDirection="column" gap="0.5rem" ml="1rem">
        <AlertTitleWrapper variant={variant}>{title}</AlertTitleWrapper>
        {children && (
          <AlertDescriptionWrapper>
            {typeof children === "string" ? (
              <Text style={{ wordBreak: "break-word" }}>{children}</Text>
            ) : (
              children
            )}
          </AlertDescriptionWrapper>
        )}
      </Flex>

      {onClick && (
        <CloseIconWrapper onClick={onClick}>
          <CloseIcon width="16px" />
        </CloseIconWrapper>
      )}
    </StyledAlert>
  );
};

export default Alert;
