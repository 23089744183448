import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";

interface LogoProps {
  height?: string | number;
  width?: string | number;
}

const Logo = ({ width, height }: LogoProps) => (
  <Link component={RouterLink} to="/">
    <img src="/static/Logo.png" alt="8 Chili" width={width} height={height} />
  </Link>
);

export default Logo;
