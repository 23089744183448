import { Helmet } from "react-helmet-async";
import { Box, Typography } from "@mui/material";
import { Policy } from "./style";
import Navbar from "./Navbar";
import Footer from "../pages/authentication/Footer";

function ServicesTerms() {
  return (
    <>
      <Helmet>
        <title>Privacy Policy</title>
      </Helmet>
      <Box className="wrapper login-main">
        <Navbar />
        <Box className="section">
          <Policy>
            <Typography color="textPrimary" variant="h3">
              Terms and Conditions
            </Typography>
            <Box style={{ padding: "20px 0" }}>
              <Typography className="content" fontSize="17px">
                Please read these terms and conditions ("terms and conditions",
                "terms") carefully before using www.8chili.com website
                (“website”, "service") operated by 8chili, Inc. ("us", 'we",
                "our"), hitherto referred to as 8chili.
                <br />
                <Typography
                  color="textPrimary"
                  variant="h5"
                  className="text_heading"
                >
                  Conditions of use
                </Typography>
                By using this website, you certify that you have read and
                reviewed this Agreement and that you agree to comply with its
                terms. If you do not want to be bound by the terms of this
                Agreement, you are advised to leave the website accordingly.
                8chili only grants use and access of this website, its products,
                and its services to those who have accepted its terms.
                <Typography
                  color="textPrimary"
                  variant="h5"
                  className="text_heading"
                >
                  Privacy policy
                </Typography>
                Before you continue using our website, we advise you to read our
                privacy policy [link to privacy policy] regarding our user data
                collection. It will help you better understand our practices.
                <Typography
                  color="textPrimary"
                  variant="h5"
                  className="text_heading"
                >
                  Age restriction
                </Typography>
                You must be at least 18 (eighteen) years of age before you can
                use this website. By using this website, you warrant that you
                are at least 18 years of age and you may legally adhere to this
                Agreement. 8chili assumes no responsibility for liabilities
                related to age misrepresentation.
                <br />
                <Typography
                  color="textPrimary"
                  variant="h5"
                  className="text_heading"
                >
                  Intellectual property
                </Typography>
                You agree that all materials, products, and services provided on
                this website are the property of 8chili, its affiliates,
                directors, officers, employees, agents, suppliers, or licensors
                including all copyrights, trade secrets, trademarks, patents,
                and other intellectual property. You also agree that you will
                not reproduce or redistribute the 8chili’s intellectual property
                in any way, including electronic, digital, or new trademark
                registrations.
                <br /> You grant 8chili a royalty-free and non-exclusive license
                to display, use, copy, transmit, and broadcast the content you
                upload and publish. For issues regarding intellectual property
                claims, you should contact the company in order to come to an
                agreement.
                <br />
                <Typography
                  color="textPrimary"
                  variant="h5"
                  className="text_heading"
                >
                  User accounts
                </Typography>
                As a user of this website, you may be asked to register with us
                and provide private information. You are responsible for
                ensuring the accuracy of this information, and you are
                responsible for maintaining the safety and security of your
                identifying information. You are also responsible for all
                activities that occur under your account or password. <br />
                <br />
                If you think there are any possible issues regarding the
                security of your account on the website, inform us immediately
                so we may address them accordingly.
                <br />
                <br />
                We reserve all rights to terminate accounts, edit or remove
                content and cancel orders at our sole discretion.
                <br />
                <br />
                <Typography
                  color="textPrimary"
                  variant="h5"
                  className="text_heading"
                >
                  Applicable law
                </Typography>
                By visiting this website, you agree that the local laws, without
                regard to principles of conflict laws, will govern these terms
                and conditions, or any dispute of any sort that might come
                between 8chili and you, or its business partners and associates.
                <br />
                <br />
                <Typography
                  color="textPrimary"
                  variant="h5"
                  className="text_heading"
                >
                  Disputes
                </Typography>
                Any dispute related in any way to your visit to this website or
                to products you purchase from us shall be arbitrated by state or
                federal court [location] and you consent to exclusive
                jurisdiction and venue of such courts.
                <br />
                <br />
                <Typography
                  color="textPrimary"
                  variant="h5"
                  className="text_heading"
                >
                  Indemnification
                </Typography>
                You agree to indemnify [name] and its affiliates and hold 8chili
                harmless against legal claims and demands that may arise from
                your use or misuse of our services. We reserve the right to
                select our own legal counsel.
                <br />
                <br />
                <Typography
                  color="textPrimary"
                  variant="h5"
                  className="text_heading"
                >
                  Limitation on liability
                </Typography>
                8chili is not liable for any damages that may occur to you as a
                result of your misuse of our website.
                <br />
                <br />
                8chili reserves the right to edit, modify, and change this
                Agreement at any time. We shall let our users know of these
                changes through electronic mail. This Agreement is an
                understanding between 8chili and the user, and this supersedes
                and replaces all prior agreements regarding the use of this
                website.
              </Typography>
            </Box>
          </Policy>
        </Box>
        <Footer />
      </Box>
    </>
  );
}

export default ServicesTerms;
