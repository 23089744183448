import Styled from "styled-components";

const TrimHeader = Styled.div`
background: #262626;
border-top-left-radius: 5px;
border-top-right-radius: 5px;
margin-top : 20px;
padding : 13px 12px;
@media only screen and (max-width:1280px){
    margin-left : 0px;
}
h2{
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    font-family: 'Mulish', sans-serif;
}
.history-list-btns {
    
    .edit {
        height: 25px;
        width: 25px;
        background-color: #323232;
        border-radius: 5px;
        cursor: pointer;
        margin: 0 10px;
        min-width: auto;
        padding: 0;
        justify-content: normal;
        align-items: normal;
        svg {
             margin :6px
        }
    };
}
`;

const Timer = Styled.div`
background: #181818;
padding : 13px 12px;
margin-bottom:${(props) => (props.margin === false ? 0 : "39px")};
alignItems : 'center';

@media only screen and (max-width:1280px){
    margin-left : 0px;

}
h2 {
font-weight: 200;
font-size: 12px;
line-height: 15px;
font-family: 'Mulish', sans-serif;
color: #FFFFFF;
padding : 5px 0;
margin-bottom 10px;

};

.MuiOutlinedInput-root {
    padding: 0;
    .MuiInputAdornment-positionEnd  {
    display: none;
    padding: 0px
    }
}

input {
    width : 110px;
    padding:0;
    height:38.34px;
    text-align : center;
    border : none;
};
.segInputTime{
    width: 97px !important;
}
 p{
    display:none
 };



`;

const DateWraper = Styled.div`
border-radius: 4.30622px;
width: min-content;
display:flex;

.time_field{
    display:flex;
}

    @media(max-width:1750px) {
        display:grid;
        width:auto;
        .add_seg_btn{
            padding-left: 25px;
            width: 205px;
        }
        .btnWidth{
            width:100%;
        }
    }

`;
const File = Styled.div`
.file {
    background: transparent;
    box-shadow: none;
    border: none;
    padding: 0;
    h2 {
        font-weight: 300;
        font-size: 14px;
        line-height: 18px;
        color: #FF852C;
        font-family: 'Mulish', sans-serif;
        margin-left: -10px;

    }
};
.file-type  {
    h2{
        font-size: 18px;
        line-height: 23px;
        color: rgba(255, 255, 255, 0.9);
        padding: 0;
        
    } 
}

`;

const ApplyTrimWraper = Styled.div`
button {
    background: ${(props) => (props.annote === true ? "none" : " rgba(255, 133, 44, 0.15)")};
    border:${(props) => (props.annote === true ? "none" : " 0.5px solid rgba(255, 133, 44, 0.6)")};
    // border: 0.5px solid rgba(255, 133, 44, 0.6);
    box-sizing: border-box;
    border-radius: 5px;
    width: ${(props) => (props.annote === true || props.isSeg ? "auto" : "110px")};
    height:39px;
    curser:pointer;
    h6 {
        color : #FF852C !important;
        font-weight: normal;
        font-size: 15px;
        line-height: 19px;
        text-align: center;
        font-family: 'Mulish', sans-serif;

    }
}
`;

const HistorySection = Styled.div`
    height:613px;

`;

const History = Styled.div`

    display: flex;
    justify-content: space-between;
    align-items: center;

    h6 {
    font-family: 'Mulish', sans-serif;
    margin-top: 20px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.9);
    margin-bottom :15px;

    };

`;

const HistoryItems = Styled.div`
img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top :39px;
};
    h3 {
        font-family: 'Mulish', sans-serif;
        font-weight: 300;
        font-size: 13px;
        line-height: 20.5px;        
        text-align: center;
        color: rgba(255, 255, 255, 0.6);
        margin-top: 12px;
    };
    
`;

const AdsImage = Styled.div`
background: linear-gradient(90deg, #0032B1 0%, rgba(14, 79, 244, 0) 100%);
border-radius: 4.19736px;
margin-top: 159px;
width: 100%;
height: 298.85px;
padding-left:32px;
img {
    display:flex;
    justify-content:flex-end;
    margin-top:12px
};
p{
    font-style: normal;
    font-weight: bold;
    font-size: 18.4684px;
    line-height: 26px;
    font-family: 'Mulish', sans-serif;
    color: #FFFFFF;
}
`;
const AdsContent = Styled.div`
h6{
    font-style: normal;
    font-weight: bold;
    font-size: 18.4684px;
    line-height: 26px;
    color: #FFFFFF;
    font-family: 'Mulish', sans-serif;
}`;

const Duration = Styled.div`
border-left :2.37112px solid rgba(255, 255, 255, 0.3);
margin-top :18px;
svg {
    margin : 2px 0px 0px 11px
};
h6{
    font-size: 13px;
    line-height: 16px;  
    font-family: 'Mulish', sans-serif;

}
.mint{
   height:18px;
}`;

const WatchNow = Styled.div`
.watchnow{
    margin-top: 25px;
          background-color: #fff;
    padding: 10px 16px;
    &:hover{
         background-color: #ebebeb;
    }
    span{
       
        color: #0083FC !important;
   font-weight: 700;
font-size: 12.538px;
line-height: 16px;
    }   
}
}`;

const VideoFooter = Styled.div`

background: #181818;
padding:25px;
display:flex;
justify-content:space-between;
@media only screen and (max-width:1280px){
    margin-left : 0px;
};
h2{
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: rgba(255, 255, 255, 0.75);   
    @media only screen and (max-width: 600px) {
        text-align:center;
    }
};  
h6{
    font-weight: normal;
font-size: 14px;
line-height: 18px;
/* identical to box height */

text-align: right;

color: rgba(255, 255, 255, 0.75);

}
.footer-nav{
    display: flex;
     justify-content: flex-end;
     @media only screen and (max-width: 600px) {
         justify-content: center;
    }
}

`;

const PlaySection = Styled.div`
.playsection{
    background: #1f1f1f;
    padding-top:10px;
    margin-top:-7px;

    width:96%;
    padding-left:10px;
        padding-right:10px;
    .progress {
        margin-top:9px;
       
        span{
            background: rgba(255, 255, 255, 0.12);
            span {
                background: #FF852C
            }
        }
    };
    .playstation {
        margin-top:11px;
        display:flex;
        justify-content:space-between;
        .time {
            h6{
                font-weight: 600;
                font-size: 14.431px;
                line-height: 18px;
                color: #FFFFFF;
                margin-top: -10px;
                span{
                    opacity:0.4
                }
            }
        }
    }
};
`;

const TrimingHelp = Styled.div`
position:relative;
height:206px;
background-color: #262626;
width: 100%;
.left_pointer{
position: absolute;
left:-12px;
top:-41px;
}

.right_pointer {
    position: absolute;
right:-12px;
top:-41px;
}
.trimText{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    h6{
        font-size: 17px;
        line-height: 25px;
        text-align: center;
        color: rgba(255, 255, 255, 0.6);
        
        }
}

`;

const VideoPlay = Styled.div`
position: absolute;
top: 0;
left: 50%;
top: 50%;
transform: translate(-50%, -50%);
bottom: auto;
cursor:pointer;
`;

const DescriptionBox = Styled.div`
textarea {
    background: rgba(0, 0, 0, 0.35);
    border: 0.533493px solid #FFFFFF;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 15px;
    box-sizing: border-box;
    height: 97px !important;
    width: 241.14px;
    color:#fff;
    padding:15px;
    @media only screen and (max-width:1000px){
        height: 57px !important;
        width: 141.14px;
    }

};
button {
    width:77.89px;
    border-radius: 3.20096px;
};
.saveBtn{
    background: rgba(255, 133, 44, 0.75);
    border: 0.533493px solid #FF852C;
    box-sizing: border-box;
    h6{
        font-weight: 600;
        font-size: 13.8708px;
        line-height: 17px;
        text-align: center;
        color: #FFFFFF;
    }
}
.closebtn {
    background-color: #ccc;
    border: none !important;
    height: 31px;
    margin-left: 10px;
    color: #000;
    cursor:pointer
};
       
`;
const TypeHere = Styled.div`
position: absolute;
left: 20%;
top: 15% ;
transform: translate(-50%, -50%);
bottom: auto;
cursor:pointer;
@media only screen and (max-width:1000px){
    left: 50%;
top: 15% ;
}
`;

const ExpandableBox = Styled.div`
position: absolute;
// left: 52%;
// top: 54%;
// transform: translate(-50%, -50%);
display:flex;
justify-content : center;
align-items : center;
bottom: auto;
.expandable-img {
    margin-left :13px;
    cursor:pointer
}

.expandable-box {
  position: absolute;
  top:  0%;
  left: 100%;
  margin-left: 30px;
}

.expandable-img-src {
  position: absolute;
  top: 40%;
  left: 100%;
}

`;

const Annoate = Styled.div`
h6{
font-weight: 300;
font-size: 12.0602px;
line-height: 15px;
font-family: 'Mulish', sans-serif;
color: #FFFFFF;
margin-bottom:15px;
};
.annoation-types {
    display: flex;    
    margin-bottom : 25px;
    span{
       padding: 0; 
        span {
            input {
               width: 17px;
                height: 17px;
            }
}
    }
.annoation-types-btns {
    display: flex;
    align-items:center;
    // margin-bottom : 25px;
 h6 {
    font-weight: 300;
    font-size: 13.7831px;
    line-height: 17px;
    font-family: 'Mulish', sans-serif;
    color: rgba(255, 255, 255, 0.5);
    margin-left:5px !important;
    padding: 0px;
    margin: 0;
    }
};
.resetBtn{
    padding-left: 20px;
}

}
.annoation-overlay {
    margin-bottom:35px;
    
    h6 {
      margin-bottom:15px
    } ;

    h5 {
    font-size: 14px;
     line-height: 18px;
     color: rgba(255, 255, 255, 0.9);
     padding:10px;
     padding-left:15px;
     padding-right:15px;
     background: rgba(255, 255, 255, 0.05);
    border-radius: 4.30622px;
    };
    .makeStyles-root-9 {
        .css-jaenh2 {
            .css-ndxkv7 {
                span {
                background-color: rgba(255, 255, 255, 0.18);
                    span{
                    background-color : white    
                    }
                }
            }
        } 
    }
}
.css-3uqyjz-MuiLinearProgress-root {
    background-color: rgba(255, 255, 255, 0.18);
    span{
        background-color : white    
        }
}
`;

const ColorSchem = Styled.div`
.titles {
    display: flex;
    justify-content: space-between;
    margin-bottom:11px;
    h6{
        font-weight: 300;
        font-size: 12.0602px;
        line-height: 15px;
        color: #FFFFFF;
        font-family: 'Mulish', sans-serif;
        margin-top: 6px;
    };
     h5 {
        font-size: 12px;
        line-height: 15px;
        color: rgba(255, 255, 255, 0.9);        
        padding:10px;
        
        background: rgba(255, 255, 255, 0.05);
        border-radius: 4.30622px;
    };
};

.css-3uqyjz-MuiLinearProgress-root {
    background-color: rgba(255, 255, 255, 0.18);
    margin-bottom:15px;

    span{
    background-color : white    
    }
}

`;

const AccordianMain = Styled.div`
.vp-accordian{
    .MuiPaper-root {
        box-shadow:none;
        .MuiButtonBase-root {
            padding: 0px;
            margin: 0px;
        };
        .MuiAccordionSummary-content{
            padding: 0px;
            margin: 0px;
            h2{
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                color: rgba(255, 255, 255, 0.8);
                margin-top: 17px;
            }
        }
    }
}

.css-15v22id-MuiAccordionDetails-root {
    padding: 22px 16px 2px;
}

`;

const HistoryList = Styled.div`
margin-top:20px;
// width: 400px;
height:42px;
background: #262626;
border-radius: 4.30723px;
display:flex;
justify-content :space-between;
.history-list{
    h6{
        font-family: 'Mulish', sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 13.7831px;
        line-height: 17px;
        color: rgba(255, 255, 255, 0.6);
        padding-top: 12px;
        margin-left:15px;
        span{
            ffont-family: 'Mulish', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 13.7831px;
            line-height: 17px;
            color: rgba(255, 255, 255, 0.9);
            margin-left: 2px
        }
        
    }
};
.history-list-btns {
    .reset {
        height: 42px;
        width: 40px;
        background-color: #323232;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        cursor:pointer;
        svg {
             margin :15px
        }
    };
    .edit {
        height: 42px;
        width: 40px;
        background-color: #323232;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        cursor:pointer;

        svg {
             margin :15px
        }
    };
}

`;

const UndoBtn = Styled.div`

    height: 40px;
    width: ${(props) =>
      props.segment ? "40px" : props.isSeg ? "140px" : props.annote ? "70px" : "auto"};
    border-radius: 5px;
    cursor:pointer;
    margin: 0 10px;
    margin-bottom: 32px;

    .jumpBtn{
        background: rgba(255,133,44,0.15);
        border: 0.5px solid rgba(255,133,44,0.6);
        box-sizing: border-box;
        border-radius: 5px;
    }
    .undoSeg{
        background-color: #323232;
        min-width: auto;
    padding: 0px;
    height: 40px;
    width: 40px;
    }
    .redoBtn{
        height: 40px;
        width: 140px;
        h6{
            font-weight: normal;
            font-size: 15px;
            line-height: 19px;
            text-align: center;
            font-family: 'Mulish', sans-serif;
        }
    }

    svg {
            margin :13px 0 0 10px;
    }

`;
export {
  Annoate,
  ColorSchem,
  AccordianMain,
  HistoryList,
  ExpandableBox,
  TypeHere,
  DescriptionBox,
  VideoPlay,
  TrimingHelp,
  PlaySection,
  VideoFooter,
  WatchNow,
  Duration,
  AdsContent,
  AdsImage,
  TrimHeader,
  History,
  HistoryItems,
  ApplyTrimWraper,
  DateWraper,
  Timer,
  HistorySection as HistorySeciton,
  File,
  UndoBtn,
};
