import { proxy } from "valtio";
import { ToastData, types as toastTypes } from "../../components/Toast";

const kebabCase = (str: string) => str.split(" ").join("-");

export interface ToastState {
  toasts: ToastData[];
}

export const toastState = proxy<ToastState>({
  toasts: [],
});

const showToastCurried =
  (type: ToastData["type"]) =>
  (title: ToastData["title"], description?: ToastData["description"]) => {
    // Not adding the date part will ensure that if multiple alerts are coming,
    // then only a single alert item will be shown.
    const id = kebabCase(title); // + Date.now();

    if (toastState.toasts.some((toast) => toast.id === id)) {
      return;
    }

    toastState.toasts.push({ id, title, description, type });
  };

export const toastError = showToastCurried(toastTypes.DANGER);
export const toastSuccess = showToastCurried(toastTypes.SUCCESS);
export const toastWarning = showToastCurried(toastTypes.WARNING);
export const toastInfo = showToastCurried(toastTypes.INFO);

export const clearAllToast = () => (toastState.toasts = []);

export const removeToast = (id: ToastData["id"]) =>
  (toastState.toasts = toastState.toasts.filter((toast) => toast.id !== id));
