import { proxy } from "valtio";
import { toastError } from "../ToastsContext";

export type LoginAPIType = (email: string, password: string) => any;

export interface UserStateType {
  data: {
    isLoggedIn: boolean;
    authToken: null | string;
    email: null | string;
    userType: null | string;
    name: null | string;
  };
  actions: {
    login: null | LoginAPIType;
    logout: null | (() => void);
  };
}

const initialUserState: UserStateType = {
  data: {
    isLoggedIn: Boolean(localStorage.getItem("auth_token")) || false,
    authToken: localStorage.getItem("auth_token"),
    email: localStorage.getItem("email"),
    userType: localStorage.getItem("userType"),
    name: localStorage.getItem("name"),
  },
  actions: {
    login: null,
    logout: null,
  },
};

export const userState = proxy(initialUserState);

export const loginAPICurried =
  (loginAPI: LoginAPIType) => async (email: string, password: string) => {
    if (!userState.data.isLoggedIn) {
      const response = await loginAPI(email, password);

      if (response.status === 200) {
        const userData = response.data;
        const userResponse = {
          isLoggedIn: true,
          authToken: userData?.auth_token,
          email: userData?.email,
          userType: userData?.type,
          name: userData?.name,
        };

        localStorage.setItem("auth_token", userData?.auth_token);
        localStorage.setItem("email", userData?.email);
        localStorage.setItem("userType", userData?.type);
        localStorage.setItem("name", userData?.name);
        userState.data = userResponse;
      } else {
        toastError(response.data.status, response.data.msg);
      }
    }
  };

export const logOutApiCurried = (logOutApi: () => any) => async () => {
  const response = await logOutApi();

  if (response.status === 200) {
    localStorage.removeItem("auth_token");
    userState.data = {} as any;
  } else {
    toastError(
      response.data?.status || "Unable to Logout",
      response.data?.msg || "Something went wrong while logging out"
    );
  }
};
