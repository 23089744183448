import PropTypes from "prop-types";
import { Box, Button, Typography } from "@mui/material";
import { ApplyTrimWraper } from "./trimStyles";

export type ApplyButtonProps = {
  current: string;
  buttonTitle: string;
  on_click_btn: () => void;
  loading: boolean;
  isEdit: boolean;
  addSeg: boolean;
  isAudio: boolean;
  shouldDisable: boolean;
};

const ApplyButton = ({
  current,
  buttonTitle,
  on_click_btn,
  loading,
  isEdit,
  addSeg,
  isAudio,
  shouldDisable,
}: Partial<ApplyButtonProps>) => (
  <>
    <Box
      style={{ alignSelf: "center" }}
      mb={isEdit || addSeg ? 0 : 4}
      mt={current === "color" ? 2 : 0}
      sx={{ display: "flex" }}
      className={addSeg ? "add_seg_btn" : ""}
    >
      <Box
        style={addSeg ? { width: "100%" } : {}}
        // mr={2}
      >
        {loading ? (
          <ApplyTrimWraper>
            <Button disabled>
              <Typography color="textPrimary" variant="h6">
                loading.....
              </Typography>
            </Button>
          </ApplyTrimWraper>
        ) : (
          <ApplyTrimWraper
            isSeg={!!isAudio}
            annote={!!isEdit}
            style={addSeg ? { width: "100%" } : {}}
          >
            <Button
              disabled={shouldDisable}
              onClick={on_click_btn}
              className={addSeg ? "btnWidth" : ""}
              style={{
                display: shouldDisable ? "none" : "",
                width: "100%",
                whiteSpace: "nowrap",
              }}
            >
              <Typography color="textPrimary" variant="h6">
                {buttonTitle}
              </Typography>
            </Button>
          </ApplyTrimWraper>
        )}
      </Box>
    </Box>
  </>
);

ApplyButton.propTypes = {
  /**
   * Title for the primary button
   */
  buttonTitle: PropTypes.string,

  /**
   * Callback invoked on clicking of primary button
   */
  on_click_btn: PropTypes.func,
};
export default ApplyButton;
