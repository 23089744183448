import { TransitionGroup } from "react-transition-group";
import styled from "@emotion/styled";
import { Toast } from "./Toast";
import { ToastContainerProps } from "./types";
import { useRef } from "react";

const ZINDEX = 1500; // because the modal have 1200 zIndex
const TOP_POSITION = 20; // Initial position from the top

const StyledToastContainer = styled.div`
  .enter,
  .appear {
    opacity: 0.01;
  }

  .enter.enter-active,
  .appear.appear-active {
    opacity: 1;
    transition: opacity 250ms ease-in;
  }

  .exit {
    opacity: 1;
  }

  .exit.exit-active {
    opacity: 0.01;
    transition: opacity 250ms ease-out;
  }

  max-height: 50vh;
  overflow-y: scroll;
`;

const getNodeTopPosition = (node: HTMLDivElement, index: number) => {
  // For the first notification
  if (!node.childNodes.length || index === 0) return TOP_POSITION;

  const { y, height } =
    // @ts-ignore
    node.childNodes?.[index - 1]?.getBoundingClientRect() || {};

  return y + height + 15;
};

export const ToastContainer: React.FC<
  React.PropsWithChildren<ToastContainerProps>
> = ({ toasts, onRemove, ttl = 6000 }) => {
  const contaienrRef = useRef<HTMLDivElement | null>(null);

  return (
    <StyledToastContainer ref={contaienrRef}>
      <TransitionGroup>
        {toasts.map((toast, index) => {
          const zIndex = (ZINDEX - index).toString();
          const top = getNodeTopPosition(
            contaienrRef.current?.firstChild as any,
            index
          );

          return (
            <Toast
              key={toast.id}
              toast={toast}
              onRemove={onRemove}
              ttl={ttl}
              style={{ top: `${top}px`, zIndex }}
            />
          );
        })}
      </TransitionGroup>
    </StyledToastContainer>
  );
};
