import Styled from "styled-components";
import { Typography } from "@mui/material";

const Heading = Styled.div`
  h2 {
    font-family: 'Mulish', sans-serif;
    font-size:30px;
    text-transform: capitalize;

    @media(max-width:831px) {
       font-size:20px;
    }

    @media(max-width:767px) {
        display:none;
    }
  }
`;

const UserGreeting = ({ name, title }) => {
  return (
    <Heading>
      <Typography color="textPrimary" variant="h2">
        {!title ? `Hello ${name}!` : title}
      </Typography>
    </Heading>
  );
};

export default UserGreeting;
