const IN_SECONDS = (time: number) => time < 9_999_999_999;

/**
 * @param dateString - string
 * @returns 23-Aug-23 format
 */
export const getShortDateFormat = (
  dateString: string,
  disableYear?: boolean
) => {
  const dateValue = parseInt(dateString);

  return new Date(
    dateValue < 9999999999 ? dateValue * 1000 : dateString
  ).toLocaleDateString("en-UK", {
    day: "2-digit",
    month: "short",
    year: disableYear ? undefined : "numeric",
  });
};

/**
 * @param dateString - string
 * @returns 21 Aug 2023, 17:07:04 format
 */
export const getShortDateWithTimeFormat = (dateString: number) =>
  new Date(
    IN_SECONDS(dateString) ? dateString * 1000 : dateString
  ).toLocaleDateString("en-UK", {
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

/**
 * @param seconds - number
 * @returns 1min 3s (string)
 */
export const getSecInSecMinuteFormat = (seconds: number) =>
  `${Math.floor(seconds / 60).toFixed()}min ${seconds % 60}s`;

export const getFormattedDate = (date: Date | number) =>
  new Date(date).toLocaleDateString("en-IN", {
    day: "2-digit",
    month: "short",
    year: "2-digit",
  });

export const getDateForNPreviousDays = (noOfDays: number) => {
  const dateToday = new Date().getDate();
  return new Date(new Date().setDate(dateToday - noOfDays)).toLocaleDateString(
    "en-IN",
    {
      day: "2-digit",
      month: "short",
      // year: "2-digit",
    }
  );
};
