import { MouseEvent, ReactNode } from "react";

export type AlertTheme = {
  background: string;
};

export const alertVariants = {
  INFO: "info",
  ERROR: "error",
  SUCCESS: "success",
  WARNING: "warning",
  DELETED: "deleted",
  ADDED: "added",
} as const;

export type Variants = (typeof alertVariants)[keyof typeof alertVariants];

export interface AlertProps {
  variant?: Variants;
  title: string;
  // description?: ReactNode;
  children?: ReactNode;
  onClick?: (evt: MouseEvent<HTMLDivElement>) => void;
}
