import { styled, Theme, CSSObject } from "@mui/material/styles";
import emotionStyled from "@emotion/styled";
import { animated, useSpring } from "react-spring";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { SidebarProps } from ".";
import { NavLink, useNavigate } from "react-router-dom";
import { ThemeProps } from "../../common/common.type";
import { useState } from "react";
import { NavigateNextRounded } from "@mui/icons-material";

const drawerWidth = 224;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "visible",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const DirectoryLink = emotionStyled(NavLink)<ThemeProps>`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;

  text-decoration: none;
  color: ${({ theme }) => theme.colors.primary};

  .MuiListItemText-primary {
    font-size: 0.875rem;
    font-weight: 500;
  }

  .active {
    background: 'pink'
  }
`;

const SidebarOpenButtonContainer = emotionStyled.div<{ open?: boolean }>`
  background: #232323;
  padding: 0.5rem;
  border-radius: 50%;

  align-items: center;
  justify-content: center;

  box-shadow: 0 0 4px #aaaaaa55;
  cursor: pointer;

  width: fit-content;
  height: fit-content;

  line-height: 0;

  position: absolute;
  top: ${({ open }) => (open ? "1.25rem" : "4.5rem")};
  right: -1rem;
  z-index: 1300;

`;

export default function MiniDrawer({ sidebarOpen, items }: SidebarProps) {
  const [open, setOpen] = useState(sidebarOpen);
  const navigate = useNavigate();

  const fade = useSpring({
    opacity: open ? 1 : 0,
  });
  const fade2 = useSpring({
    opacity: !open ? 1 : 0,
  });

  const toogleDrawerOpen = () => {
    setOpen((opn) => !opn);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Drawer
        variant="permanent"
        open={open}
        style={{
          marginRight: "1rem",
          overflow: "visible",
        }}
        PaperProps={{
          style: {
            margin: "1rem",
            height: "calc(100vh - 2rem)",
            background: "#181818aa",
            backdropFilter: "blur(1px)",
            borderRadius: "1rem",
            overflow: "visible",
            transition: "all 0.4s",
          },
        }}
      >
        <DrawerHeader sx={{ justifyContent: "flex-start" }}>
          <IconButton
            onClick={() => navigate("/dashboard")}
            style={{ padding: 0 }}
          >
            <div
              style={{ padding: open ? 1 : 0, margin: open ? "0 1rem" : "" }}
            >
              {open ? (
                <animated.div style={fade}>
                  <img
                    src="/FullLogo.png"
                    height={40}
                    style={{ marginTop: "1rem" }}
                  />
                </animated.div>
              ) : (
                <animated.div style={fade2}>
                  <img src="/Logo.png" style={{ marginTop: "1rem" }} />
                </animated.div>
              )}
            </div>
          </IconButton>
        </DrawerHeader>
        <Divider style={{ marginBottom: open ? "" : "2.5rem" }} />
        <SidebarOpenButtonContainer open={open} onClick={toogleDrawerOpen}>
          <NavigateNextRounded
            style={{
              fill: "white",
              transform: open ? "rotate(180deg)" : undefined,
              transition: "all 0.4s",
            }}
          />
        </SidebarOpenButtonContainer>
        <List>
          {items.map((item, i) => (
            <DirectoryLink
              key={item.to + "-" + i}
              to={item.to}
              style={({ isActive }) => ({
                background: isActive ? "#00000075" : "",
                margin: isActive && !open ? "0 0.5rem" : "0 1rem",
                borderRadius: "1rem",
              })}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 1.25 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {<item.icon width="24px" />}
                </ListItemIcon>
                <ListItemText
                  primary={item.name}
                  sx={{
                    opacity: open ? 1 : 0,
                    color: "white",
                  }}
                />
              </ListItemButton>
            </DirectoryLink>
          ))}
        </List>
      </Drawer>
    </Box>
  );
}
