import { useEffect } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { useMediaQuery, useTheme } from "@mui/material";
import { Box, Drawer } from "@mui/material";
import { HoverEffect } from "./style";
import Scrollbar from "./Scrollbar";
import NavSection from "./NavSection";
import Sectionsicon from "../icons/Section";
import CutIcon from "../icons/CutIcon";

const sections = [
  {
    items: [
      {
        title: "Overview",
        path: "/dashboard",
        icon: <img src="/static/Logo.png" alt="logo" width="100%" />,
      },
      {
        title: "Cut",
        path: "/video/cut",
        icon: (
          <CutIcon
            fontSize="large"
            style={{ fontSize: "30px", marginTop: "20px", marginLeft: "7px" }}
          />
        ),
      },
      {
        title: "Segments",
        path: "/video/lecture_parts",
        icon: (
          <Sectionsicon
            fontSize="large"
            style={{ fontSize: "30px", marginTop: "20px", marginLeft: "7px" }}
          />
        ),
      },
    ],
  },
] as const;

const VideoSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const theme = useTheme();
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <HoverEffect>
          <Box sx={{ p: 0 }}>
            {sections.map((section, idx) => (
              // @ts-ignore
              <NavSection
                key={`$nav-section-${idx}`}
                pathname={location.pathname}
                {...section}
              />
            ))}
          </Box>
        </HoverEffect>
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: "background.paper",
            height: "calc(100% - 0px) !important",
            top: "0px !Important",
            width: 70,
            border: "none",
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: "background.paper",
          width: 70,
          border: "none",
        },
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

VideoSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default VideoSidebar;
