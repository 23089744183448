export enum ContentTypeEnum {
  User = "User",
  Creator = "creator",
  Orgs = "orgs",
  Course = "courses",
  Lecture = "lectures",
  Podcasts = "podcasts",
  Recordings = "recordings",
  Simulations = "simulations",
}
