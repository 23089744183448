import { createContext, useMemo } from "react";
import { useSnapshot } from "valtio";
// import { devtools } from "valtio/utils";
import {
  clearAllToast,
  removeToast,
  toastError,
  toastInfo,
  toastState,
  toastSuccess,
  toastWarning,
} from "./toast.state";
import type { ToastContextApi } from "./types";

export const ToastsContext = createContext<ToastContextApi | undefined>(
  undefined
);

// devtools(toastState, { name: "toastState", enabled: true });

export const ToastsProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const snap = useSnapshot(toastState, { sync: true });

  const providerValue = useMemo(() => {
    return {
      toasts: snap.toasts,
      clear: clearAllToast,
      remove: removeToast,
      toastError: toastError,
      toastInfo: toastInfo,
      toastSuccess: toastSuccess,
      toastWarning: toastWarning,
    };
  }, [snap.toasts]);

  return (
    <ToastsContext.Provider value={providerValue}>
      {children}
    </ToastsContext.Provider>
  );
};
