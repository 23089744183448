import { Outlet } from "react-router-dom";
import { useCallback, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { experimentalStyled } from "@mui/material";
import { Box } from "@mui/material";
import { apiService } from "@8chili/services";
import VideoNavbar from "./VideoPageHeader";
import VideoSidebar from "./VideoLayoutSideBar";
import VideoSectionFooter from "./video/Footer";
import VideoContext from "../contexts/video";

const VideoLayouttRoot = experimentalStyled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "flex",
  height: "100%",
  overflow: "hidden",
  width: "100%",
}));

const VideoLayoutWrapper = experimentalStyled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
  paddingTop: "64px",
  [theme.breakpoints.up("lg")]: {
    paddingLeft: "100px",
  },
}));

const VideoLayoutContainer = experimentalStyled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
});

const VideoLayout = ({ no_sidebar }) => {
  const { videoDispatcher, lectureDetails, recordingDetails } =
    useContext(VideoContext);
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [_, setlectureData] = useState([]);
  const [__, setrecordingData] = useState([]);
  const recordingId = localStorage?.getItem("recording_id");
  const lecture_id = localStorage.getItem("lecture_id");

  const getLectureDetail = useCallback(async () => {
    setLoading(true);

    try {
      // This is to remove the above API call on recording page.
      const res: any = {};

      setlectureData(res.data);
      videoDispatcher({
        type: "SET_LECTURE_DETAILS",
        payload: {
          ...res.data,

          cut_intervals:
            res.data.recording_details?.cut_intervals?.map(
              ([startTime, endTime], index) => ({
                startTime,
                endTime,
                index: String(index),
              })
            ) || [],
          annotations:
            res.data.recording_details?.annotations?.map((annotate) => ({
              ...annotate,
              startTime: annotate.play_time,
              type: annotate.type === "text" ? "SPEECH" : "REGION",
              description: annotate.name,
              width: annotate?.width,
              height: annotate?.height,
            })) || [],
          color_corrections:
            res.data.color_corrections?.map((correction) => ({
              ...correction,
              startTime: correction.start,
              endTime: correction.end,
              value: correction.corrections,
            })) || [],

          voices: res.data.recording_details?.segments?.map((voice) => ({
            ...voice,
            startTime: voice?.start_time || 0,
            endTime: voice?.end_time || 0,
            audio: voice?.audio_url,
            type: "segment",
            title: voice?.name,
            status: voice?.id,
            id: voice?.id,
          })),
        },
      });
      videoDispatcher({
        type: "UPDATE_VIDEO_DURATION",
        payload: res.data.recording_details.end_time || 0,
      });

      if (
        res.data.recording_details.start_time !== null &&
        res.data.recording_details.end_time !== null
      ) {
        videoDispatcher({
          type: "UPDATE_TRIM_HISTORY",
          payload: {
            startTime: res.data?.recording_details?.start_time,
            endTime: res.data?.recording_details?.end_time,
          },
        });
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }, []);

  const getRecordings = useCallback(async () => {
    try {
      const recRes = await apiService.getData(
        `/api/video/recording_details/${recordingId}`
      );
      const recData = recRes?.data?.details;
      setrecordingData(recData);
      videoDispatcher({
        type: "SET_RECORDING_DETAILS",
        payload: {
          ...recRes.data.details,

          cut_intervals:
            recData?.cut_intervals?.map(([startTime, endTime], index) => ({
              startTime,
              endTime,
              index: String(index),
            })) || [],
          annotations:
            recData?.annotations?.map((annotate) => ({
              ...annotate,
              startTime: annotate.play_time,
              type: annotate.type === "text" ? "SPEECH" : "REGION",
              description: annotate.name,
              width: annotate?.width,
              height: annotate?.height,
            })) || [],
          color_corrections:
            recData?.color_corrections?.map((correction) => ({
              ...correction,
              startTime: correction.start,
              endTime: correction.end,
              value: correction.corrections,
            })) || [],

          voices: recData?.segments?.map((voice) => ({
            ...voice,
            startTime: voice?.start_time || 0,
            endTime: voice?.end_time || 0,
            audio: voice?.audio_url,
            type: "segment",
            title: voice?.name,
            status: voice?.id,
            id: voice?.id,
            pause_intervals: voice?.pause_intervals,
          })),
        },
      });
      videoDispatcher({
        type: "UPDATE_VIDEO_DURATION",
        payload: recData?.duration || 0,
      });
      videoDispatcher({
        type: "UPDATE_TRIM_TIMER",
        payload: {
          startTime: recordingDetails?.start_time || 0,
          endTime: recordingDetails?.duration,
        },
      });
      if (recData.start_time !== null && recData.end_time !== null) {
        videoDispatcher({
          type: "UPDATE_TRIM_HISTORY",
          payload: {
            startTime: recData?.start_time,
            endTime: recData?.duration,
          },
        });
      }

      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    if (recordingId) {
      videoDispatcher({
        type: "UPDATE_CUT_HISTORY",
        payload: recordingDetails?.cut_intervals || [],
      });

      videoDispatcher({
        type: "UPDATE_ANNOTE_HISTORY",
        payload: recordingDetails?.annotations || [],
      });

      videoDispatcher({
        type: "UPDATE_COLOR_HISTORY",
        payload: recordingDetails?.color_corrections || [],
      });
      videoDispatcher({
        type: "ADD_VOICE",
        payload: recordingDetails?.voices || [],
      });
      videoDispatcher({
        type: "UPDATE_TRIM_TIMER",
        payload: {
          startTime: recordingDetails?.start_time || 0,
          endTime: recordingDetails?.duration,
        },
      });
    } else {
      videoDispatcher({
        type: "UPDATE_CUT_HISTORY",
        payload: lectureDetails?.cut_intervals || [],
      });

      videoDispatcher({
        type: "UPDATE_ANNOTE_HISTORY",
        payload: lectureDetails?.annotations || [],
      });

      videoDispatcher({
        type: "UPDATE_COLOR_HISTORY",
        payload: lectureDetails?.color_corrections || [],
      });
      videoDispatcher({
        type: "ADD_VOICE",
        payload: lectureDetails?.voices || [],
      });
    }
  }, [lectureDetails, recordingDetails]);

  useEffect(() => {
    if (lecture_id) {
      getLectureDetail();
    }
    if (recordingId) {
      getRecordings();
    }
  }, [getLectureDetail, getRecordings, recordingId]);

  if (loading) {
    return <>Loading</>;
  }
  return (
    <VideoLayouttRoot>
      <VideoNavbar onSidebarMobileOpen={() => setIsSidebarMobileOpen(true)} />

      {!no_sidebar && (
        <VideoSidebar
          onMobileClose={() => setIsSidebarMobileOpen(false)}
          openMobile={isSidebarMobileOpen}
        />
      )}

      <VideoLayoutWrapper>
        <VideoLayoutContainer>
          <Box className="dashboardLayoutContent">
            <Outlet />
            <VideoSectionFooter />
          </Box>
        </VideoLayoutContainer>
      </VideoLayoutWrapper>
    </VideoLayouttRoot>
  );
};

VideoLayout.propTypes = {
  children: PropTypes.node,
};

export default VideoLayout;
