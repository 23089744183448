import PerfectScrollbar, { ScrollBarProps } from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

const Scrollbar = (props: ScrollBarProps) => {
  const { children, ...other } = props;

  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  if (isMobile) {
    return <Box sx={{ overflowX: "auto" }}>{children}</Box>;
  }

  return <PerfectScrollbar {...other}>{children}</PerfectScrollbar>;
};

Scrollbar.propTypes = {
  children: PropTypes.node,
};

export default Scrollbar;
